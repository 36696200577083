function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="vuesax/twotone/microphone-2" xmlns="http://www.w3.org/2000/svg">
          <g id="vuesax/twotone/microphone-2_2">
            <g id="microphone-2">
              <path
                id="Vector_3"
                d="M16.00055,20.083c2.5639,0 4.6406,-2.0883 4.6406,-4.6667v-6.4166c0,-2.5784 -2.0767,-4.6667 -4.6406,-4.6667c-2.564,0 -4.6406,2.0883 -4.6406,4.6667v6.4166c0,2.5784 2.0766,4.6667 4.6406,4.6667z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.16916"
              />
              <path
                id="Vector_4"
                d="M14.38795,9.5016c1.0442,-0.385 2.1811,-0.385 3.2253,0"
                opacity="0.4"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.16916"
              />
              <path
                id="Vector_5"
                d="M15.07235,11.9755c0.6149,-0.1639 1.2529,-0.1639 1.8678,0"
                opacity="0.4"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.16916"
              />
              <g id="Group 595" opacity="0.4">
                <path
                  id="Vector_6"
                  d="M7.12485,13.2588v1.9833c0,4.9234 3.9793,8.925 8.8751,8.925c4.8959,0 8.8752,-4.0016 8.8752,-8.925v-1.9833"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.16916"
                />
                <path
                  id="Vector_7"
                  d="M16.00055,24.167v3.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.16916"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
