function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="chevron-down" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector"
            d="M15.2085,18.4165l-6.417,-6.416l6.417,-6.417"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.7"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
