import { useEffect, useState } from 'react';
import { Span, View } from 'app-studio';
import EventCard from '../../../../components/EventCard';

import SvgIcon1 from './icons/SvgIcon1';
import SvgIcon2 from './icons/SvgIcon2';
import { Horizontal } from '@app-studio/web';
import i18n from 'src/utils/locale';

function AccountProtestList({ data }) {
  if (!data) return null;

  const [page, setPage] = useState(0);
  const [pageSize, setPagesize] = useState(parseInt(window.innerWidth / 330));

  let totalPage = parseInt(data.length / pageSize) + 1;

  window.addEventListener('resize', () => {
    setPagesize(parseInt(window.innerWidth / 330));
    totalPage = parseInt(data.length / pageSize) + 1;
  });

  return (
    <ProtestContainer>
      <ProtestHeading>{i18n.t('page.account.protests')}</ProtestHeading>
      <ProtestInfo>
        <Span>{i18n.t('page.account.participateIn')}</Span>
        <ProtestCount>({data.length})</ProtestCount>
        <Span> {i18n.t('page.account.protests')}.</Span>
      </ProtestInfo>
      <ProtestSection>
        <EventList>
          {data
            .slice(pageSize * page, pageSize * page + pageSize)
            .map((event) => (
              <EventContainer>
                <EventCard event={event} />
              </EventContainer>
            ))}
        </EventList>
      </ProtestSection>
      <PageNavigation>
        <ContainerWithSvg onClick={() => setPage(page > 1 ? page - 1 : 0)}>
          <SvgIcon1 />
        </ContainerWithSvg>
        <PageCounter>
          Page {page + 1} / {totalPage}
        </PageCounter>
        <ContainerWithSvg
          onClick={() =>
            setPage(page + 1 < totalPage ? page + 1 : totalPage - 1)
          }
        >
          <SvgIcon2 />
        </ContainerWithSvg>
      </PageNavigation>
    </ProtestContainer>
  );
}

export default AccountProtestList;

const ProtestCard = (props) => (
  <Horizontal gap={10} borderRadius={8} marginVertical={20} {...props} />
);

const ProtestHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const ProtestInfo = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={8}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);

const ProtestCount = (props) => (
  <Span
    fontFamily={'Work Sans'}
    fontWeight={600}
    color={'rgba(85, 52, 165, 1)'}
    {...props}
  />
);

const ProtestContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={32}
    {...props}
  />
);

const PageNavigation = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'space-between'}
    marginTop={20}
    width="100%"
    {...props}
  />
);

const ContainerWithSvg = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={48}
    height={48}
    padding={15}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(16, 24, 40, 0.05)'}
    {...props}
  />
);

const PageCounter = (props) => (
  <View font={'500 18px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const ProtestSection = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    gap={20}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const EventList = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={20}
    alignItems={'flex-start'}
    {...props}
  />
);

const EventContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'1 1 0'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    maxWidth={291}
    paddingRight={15.5}
    paddingBottom={10}
    paddingLeft={15.5}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);
