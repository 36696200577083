import { View, Image } from 'app-studio';
import { Button } from '@app-studio/web';
import { useCallback, useState, forwardRef } from 'react';
import AnonymousChat from '../AnonymousChat';
import SvgIcon1 from './icons/SvgIcon1';
import SvgIcon2 from './icons/SvgIcon2';

function EnvironmentalCauseCard() {
  return (
    <OxygenCauseContainer>
      {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <DeforestationButton>The cause</DeforestationButton>
      <OxygenText>
        Stop the deforestation of the Amazon. It is our oxygen !
      </OxygenText>
      <FlexRowContainer>
        <FlexRowContainer>
          <SvgIcon1 />
          <LiveNowText>Live now</LiveNowText>
        </FlexRowContainer>
        <CountdownContainer>
          <SvgIcon2 />
          <LiveNowText>34:08 minutes reaming</LiveNowText>
        </CountdownContainer>
      </FlexRowContainer>
      <AnonymousChat />
    </OxygenCauseContainer>
  );
}

export default EnvironmentalCauseCard;

const OxygenCauseContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    gap={16}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={32}
    {...props}
  />
);

const DeforestationButton = (props) => (
  <Button
    boxSizing={'border-box'}
    display={'block'}
    flex={'0 0 auto'}
    width={86}
    height={32}
    font={'500 14px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    cursor={'pointer'}
    background={'rgba(85, 52, 165, 1)'}
    border={'none'}
    borderRadius={2}
    {...props}
  />
);

const OxygenText = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={'-6px'}
    font={'600 32px/120.00000762939453% Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    textAlign={'left'}
    {...props}
  />
);

const FlexRowContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const LiveNowText = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={6}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);

const CountdownContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    marginLeft={16}
    {...props}
  />
);
