import { useCallback, useState, forwardRef } from 'react';
import PasswordResetForm from './components/PasswordChangeForm';
import SvgIcon1 from './icons/SvgIcon1';
import './style.css';

function PasswordChangePage() {
  return (
    <div className={`protest-container2`}>
      <div className={`protest-container`}>
        <div className={`protest-container1`}>
          <img className={`image-container img-content-9f6aa9a3`} />
          <SvgIcon1 className="svg-container" />
        </div>
        <p className={`virtual-protest-title`}>Virtual protest</p>
      </div>
      <PasswordResetForm />
    </div>
  );
}

export default PasswordChangePage;
