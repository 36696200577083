import { useCallback, useState, forwardRef } from 'react';
import EnvironmentalCauseCard from './components/EnvironmentalCauseCard';
import SvgIcon1 from './icons/SvgIcon1';
import './style.css';

function EnvironmentalAwareness() {
  return (
    <div className={`campaign-container`}>
      <div className={`flex-container`}>
        <div className={`container-with-svg`}>
          <div className={`gradient-background-container`} />
        </div>
        <div className={`svg-container4`}>
          <SvgIcon1 className="svg-container" />
        </div>
      </div>
      <EnvironmentalCauseCard />
    </div>
  );
}

export default EnvironmentalAwareness;
