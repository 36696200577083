import { DEFAULT_LANGUAGE } from 'src/configs/AppConfig';
import { I18n } from 'i18n-js';
import en from 'src/locales/en';
import fr from 'src/locales/fr';
import { isBrowser } from 'src/utils/env';

export const i18n = new I18n({
  en,
  fr,
});

i18n.defaultLocale = DEFAULT_LANGUAGE;
i18n.locale = DEFAULT_LANGUAGE;

export const locale = {
  availableLanguages: Object.keys(i18n.translations),

  getLocale: (language: string) => {
    if (language.indexOf('en') == 0) return 'en';
    if (language.indexOf('fr') == 0) return 'fr';

    return DEFAULT_LANGUAGE;
  },

  t: i18n.t,
};

export default i18n;
