import { View, Input, Image, Span } from 'app-studio';
import { Button } from '@app-studio/web';
import { forwardRef, useCallback, useState } from 'react';
import i18n from 'src/utils/locale';
import FormatNumber from 'src/utils/formatNumber';

function ParticipantList({
  items = [],
  total = 0,
}: {
  items: any[];
  total: number;
}) {
  if (items.length == 0) return <ParticipantContainer />;
  return (
    <ParticipantContainer>
      <ParticipantTitle>{i18n.t('page.home.participants')} :</ParticipantTitle>
      <ParticipantItems>
        {items.map((item, index) =>
          index == 0 ? (
            <ProtestInfo key={'protest_' + index}>
              <CircularImageImg src={item.avatar} />
            </ProtestInfo>
          ) : (
            <CardContainer key={'protest_' + index}>
              <CircularImageImg src={item.avatar} />
            </CardContainer>
          )
        )}
        {total > 4 && (
          <CardContainer>
            <RoundedButton>{FormatNumber(total - 4)}+</RoundedButton>
          </CardContainer>
        )}
      </ParticipantItems>
    </ParticipantContainer>
  );
}

export default ParticipantList;

const ParticipantContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    height={64}
    borderTop={'1px solid #ededee'}
    marginTop={20}
    marginBottom={20}
    {...props}
  />
);

const ParticipantTitle = (props) => (
  <View
    flex={'0 0 auto'}
    font={'400 14px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    maxWidth={120}
    {...props}
  />
);

const ParticipantItems = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'flex-start'}
    justifyContent={'flex-start'}
    paddingTop={15.5}
    paddingBottom={16}
    marginLeft={8}
    {...props}
  />
);

const ProtestInfo = (props) => <View flex={'0 0 auto'} {...props} />;

const CircularImageImg = ({ src }) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    src={src}
  />
);

const CardContainer = (props) => (
  <View flex={'0 0 auto'} marginLeft={'-8px'} {...props} />
);

const CircularImage1ImgContentCff6d1b5 = (props) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    content={
      'url("https://s3-alpha-sig.figma.com/img/ff12/59d3/291db622f48fe88806b349666b6fb1b8?Expires=1699833600&Signature=gHuTmcCEgCbCrhrdmvwnLJXvE7Y4IjOVsZOrfmxRcisPX2DbtjGOYCzSoVHYj7qTNgYxWVn6XyJlzPtndORPX2oXDARdIl9Q6MNbGl97mMIHZPpDMBXlyEo9p3MuShIZOfzRDlY74oN6XAy2N2gvkgUU~qQmcWbrFBD4TaFr31x0Ka-73XzZQiUQNc0O4DfuG6uyg78GjErCk290XU4yZn0F5G65x10qg9HNASTcaHxHH0ZdaWcPuppP~CXC0yIvKVLUMinncnQY6pN8MUEC-3knpctJ~vhyQFL82fNcr5U1JkWRx0Rk0QzCOcuwrfhaNkIcdGsZCbxje8KXNZ-eJw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4")'
    }
    {...props}
  />
);

const CircularImage1ImgContent2b516f6d = (props) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    content={
      'url("https://s3-alpha-sig.figma.com/img/38ab/233e/d540c618577c001b3a4a83f67e35158a?Expires=1699833600&Signature=DGiVaNx03u3lfsDOZ-ELQOgpFqgJh1eqhsKFDxh9bzE2w0VvNQ9u3izfGNke4YOJPNXeIXVabGw5a5uyqyXQeSTE8enZCV~WiiIUB7HnfcuEH0CLGlptNlhvBs9NYXRJvRLt4uOELYE0-BfhZAPv06KB-EpQeSmwxxSslxxT~QHoNBVs0qAky5BYNh7fu4UlpdRRaMI142YglnH~waaAf9R9knbD61xBMapnLWedHX5pQ21TojKFxvy1qVhvuweYbUaZmHwMdCzuELj7bVfEVo57xtzNht3JAzuGHim6dtP9Bv8kbhg-AM1a6FkJUgGLApQwifGWIrR6Zd506NPVzQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4")'
    }
    {...props}
  />
);

const CircularImage1ImgContentE6d5ca33 = (props) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    content={
      'url("https://s3-alpha-sig.figma.com/img/62b9/7e76/81e422c8cb1db63a2bfb60deac23bb18?Expires=1699833600&Signature=W~vtYUSHodM~S6zhDf2hHCika7d-kgSF-BhZR4jwWtEPhTtqxGw91PD-saTdNxUJ4Xd8NjcGsCuJP5nfVAMvrL7mDxqjmzuvPEkMDP7640FB1evpu4Uc67G8rQd3-ixutulVWC3PfTHE4TG2-97QY9ZJ~VflBWRDYskZUVdFF5yoDj5Giz1RXfsw~iuHXwmAYyjWwxqxtd6fi6t1Iygeh~P5R1PdUYnhTwpKcULcWDJ8b8-ROTzHMR~1YOHuPixbWOXI292Sj9C2k~L~t4MLTakOI8nvZlYzHGg3YwmRAmXIDHWh649w85ZM1JO1Vrv-WgPi448yr1UCCqvi6dJ8WQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4")'
    }
    {...props}
  />
);

const RoundedButton = (props) => (
  <Span
    boxSizing={'border-box'}
    display={'inline-flex'}
    alignItems={'center'}
    justifyContent={'center'}
    width={38}
    height={38}
    paddingRight={4.5}
    paddingLeft={4.5}
    marginLeft={2}
    font={'500 10px Work Sans'}
    color={'rgba(85, 52, 165, 1)'}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(85, 52, 165, 1)'}
    borderRadius={'50%'}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);
