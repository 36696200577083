function SvgIcon2({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <g id="clock_2" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector_17"
          d="M10,6.667v3.333l2.5,2.5M17.5,10c0,0.985 -0.19,1.96 -0.57,2.87c-0.38,0.91 -0.93,1.737 -1.63,2.433c-0.69,0.697 -1.52,1.249 -2.43,1.626c-0.91,0.377 -1.89,0.571 -2.87,0.571c-0.98,0 -1.96,-0.194 -2.87,-0.571c-0.91,-0.377 -1.74,-0.929 -2.43,-1.626c-0.7,-0.696 -1.25,-1.523 -1.63,-2.433c-0.38,-0.91 -0.57,-1.885 -0.57,-2.87c0,-1.989 0.79,-3.897 2.2,-5.303c1.4,-1.407 3.31,-2.197 5.3,-2.197c1.99,0 3.9,0.79 5.3,2.197c1.41,1.406 2.2,3.314 2.2,5.303z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        />
      </g>
    </svg>
  );
}

export default SvgIcon2;
