function SvgIcon1({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
    >
      <g id="arrow-left" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Icon_3"
          d="M22.6,12h-21.2M1.4,12l7,7M1.4,12l7,-7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default SvgIcon1;
