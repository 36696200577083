import { View } from 'app-studio';
import SvgIcon1 from './icons/SvgIcon1';
import { useNavigate } from 'react-router';

function TopBar() {
  const navigate = useNavigate();

  return (
    <ProtestContainer>
      <ProtestContainer1
        onClick={() => {
          navigate('/');
        }}
      >
        <VirtualProtestContainer>
          <ImageContainerImgContentE9deb7c1>
            <SvgIcon1 />
          </ImageContainerImgContentE9deb7c1>
        </VirtualProtestContainer>
        <VirtualProtestTitle>Virtual protest</VirtualProtestTitle>
      </ProtestContainer1>
    </ProtestContainer>
  );
}

export default TopBar;

const ProtestContainer = (props) => (
  <View
    boxSizing={'border-box'}
    width={'50vw'}
    height={'100vh'}
    padding={40}
    background={'rgba(246, 243, 252, 1)'}
    media={{ mobile: { display: 'none' } }}
    {...props}
  />
);

const ProtestContainer1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const VirtualProtestContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const ImageContainerImgContentE9deb7c1 = (props) => (
  <View
    boxSizing={'border-box'}
    display={'block'}
    flex={'0 0 auto'}
    alignSelf={'stretch'}
    width={33}
    maxWidth={'initial'}
    height={41}
    color="white"
    paddingTop={15}
    paddingHorizontal={7}
    backgroundImage={'url("/assets/img-content-e9deb7c1.svg")'}
    {...props}
  />
);

const VirtualProtestTitle = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={7.25}
    font={'600 22px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const RoundedContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={50}
    height={50}
    padding={10}
    color="grey"
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={'50%'}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);
