import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import { View } from 'app-studio';
import axios from 'axios';
import {
  FileSVG,
  FormikPassword,
  FormikTextField,
  FormikForm,
  Button,
  Vertical,
} from '@app-studio/web';
import * as Yup from 'yup';
import { isDev } from 'src/utils/env';

import { ServiceService } from 'src/services/api';
import { Center } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import { Label } from 'src/components/Label';
import { GoogleOutlined, TwitterOutlined } from '@ant-design/icons';
import { FcGoogle } from 'react-icons/fc';
import { FaTwitter } from 'react-icons/fa';
import googleOneTap from 'google-one-tap';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(i18n.t('form.email.required')),

  password: Yup.string()
    .required(i18n.t('form.password.required'))
    .min(4, i18n.t('form.password.short')),
});

const LoginForm = () => {
  const { setToken } = useAuthStore();
  const navigate = useNavigate();

  const { loading, run } = ServiceService.useLoginUserService({
    onSuccess: ({ body }) => {
      if (body.token) {
        setToken(body.token, body.email);
        navigate('/');
      }
    },
  });

  const googleOneTapOptions = {
    client_id: process.env.GOOGLE_CLIENT_ID, // required
    auto_select: false, // optional
    cancel_on_tap_outside: false, // optional
    context: 'signin', // optional
  };

  const handleGoogleSignIn = () => {
    googleOneTap(googleOneTapOptions, async (response: any) => {
      console.log(response);
      try {
        const res = await fetch(
          `${process.env.GOOGLE_REDIRECT_URI}&code=${response.credential}`,
          {
            method: 'GET',
          }
        );
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const dataFromBackEnd = await res.json();
        console.log(dataFromBackEnd);

        if (dataFromBackEnd.statusCode === 200) {
          if (dataFromBackEnd.body.token) {
            setToken(
              dataFromBackEnd.body.token,
              dataFromBackEnd.body.user.email
            );
            navigate('/');
          }
        } else {
          alert(i18n.t('page.login.googleSignInProblem'));
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
      }
    });
  };

  const handleTwitterSignIn = async () => {
    console.log('here');
    const url = `${process.env.REACT_APP_API_URL}/users/oauth2/twitter/get-request-token`;

    try {
      const response = await axios.get(url);

      console.log(response);

      if (response.data.statusCode == 200 && response.data.body.url) {
        console.log('We have an url');
        window.location.href = response.data.body.url; // Redirection towards Twitter for authentication

        //Explication de la logique attendue:
        // Si la redirection se passe bien vers l'URL donné par Twitter, l'utilisateur va pouvoir authoriser Twitter pour s'authentifier
        // Twitter va ensuite rediriger l'utilisateur vers l'URL de callback donné dans l'application Twitter: {BASE_PATH}/oauth2/callback-twitter
        ///oauth2/callback-twitter est déclaré dans  src/configs/RouterConfig.tsx et est associé à src/components/TwitterRedirect.tsx
        // Le composant TwitterRedirect.tsx doit ensuite appeler l'endpoint de callback du backend /users/oauth2/twitter/twitter-callback qui va soit enregistrer l'utilisateur dans la base de données, soit le connecter s'il existe déjà. Les infos du user sont récupérer depuis Twitter dans le code de ce callback.
      } else {
        console.error(
          'Erreur lors de la récupération du request token Twitter'
        ); //TODO: Internationaliser
      }
    } catch (error) {
      console.error('Erreur lors de la connexion à Twitter:', error); //TODO: Internationaliser
      alert('Erreur lors de la connexion à Twitter:' + error); //TODO: Internationaliser
    }
  };

  return (
    <Formik
      onSubmit={(values) => {
        run(values);
      }}
      validationSchema={validationSchema}
      initialValues={
        isDev()
          ? {
              email: 'your.email@gmail.com',
              password: 'test',
            }
          : {}
      }
    >
      {({ handleSubmit }) => {
        return (
          <FormikForm>
            <Vertical gap={10}>
              <Label>{i18n.t('form.email.label')}</Label>
              <FormikTextField
                placeholder={i18n.t('form.email.placeholder')}
                name="email"
                type="email"
                shape={'rounded'}
                variant={'outline'}
                rightIcon={<FileSVG src={'/assets/email.svg'} />}
              />
              <Label>{i18n.t('form.password.label')}</Label>
              <FormikPassword
                placeholder={i18n.t('form.password.placeholder')}
                name="password"
                shape={'rounded'}
                variant={'outline'}
              />

              <Center marginVertical={20}>
                <Button
                  isLoading={loading}
                  type="submit"
                  width="100%"
                  onClick={handleSubmit}
                  isDisabled={loading}
                >
                  {i18n.t('button.login')}
                </Button>
              </Center>

              <h2>{i18n.t('page.login.orContinueWith')}</h2>
              {/* <ButtonAuth onClick={handleGoogleSignIn}>
                <FcGoogle />
                <p>Google</p>
              </ButtonAuth> */}
              <ButtonAuth onClick={handleTwitterSignIn}>
                <FaTwitter color="#1DA1F2" />
                <p>Twitter</p>
              </ButtonAuth>
            </Vertical>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default LoginForm;

const ButtonAuth = (props) => {
  return (
    <View
      backgroundColor={'#FFF'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={10}
      borderRadius={10}
      boxShadow={'0px 2px 3px rgba(16, 24, 40, 0.2)'}
      paddingHorizontal={10}
      paddingVertical={10}
      marginVertical={5}
      {...props}
    />
  );
};
