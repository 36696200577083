import { View, Input, Image } from 'app-studio';
import { Button, Svg } from '@app-studio/web';
import { forwardRef, useCallback, useState } from 'react';
import i18n from 'src/utils/locale';

import ParticipantDashboard from '../ParticipantDashboard';

function ParticipantTrend({ data }) {
  if (!data || !data.participantsMeasurements) return null;

  return (
    <ProtestDetails>
      <ProtestHeading>{i18n.t('page.event.trends')}</ProtestHeading>
      <ParticipantTrendComponent>
        {i18n.t('page.event.numberOfParticipants')}
      </ParticipantTrendComponent>
      <ParticipantDashboard data={data} />
    </ProtestDetails>
  );
}

export default ParticipantTrend;

const ProtestDetails = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    marginTop={30}
    justifyContent={'flex-start'}
    {...props}
  />
);

const ProtestHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const ParticipantTrendComponent = (props) => (
  <View
    flex={'0 0 auto'}
    marginVertical={10}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);
