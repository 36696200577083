import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'src/stores/AuthStore';
import i18n from 'src/utils/locale';
import axios from 'axios';

const TwitterRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(true);

  const { setToken } = useAuthStore();

  useEffect(() => {
    if (!isProcessing) return;

    const query = new URLSearchParams(location.search);
    const oauthToken = query.get('oauth_token');
    const oauthVerifier = query.get('oauth_verifier');

    if (oauthToken && oauthVerifier) {
      callBackToRegisterUserInTheSystem(
        oauthToken,
        oauthVerifier,
        setToken,
        navigate
      );
      setIsProcessing(false);
    }
  }, [location.search]); // Seulement `location.search` est nécessaire ici

  // Afficher un spinner ou un message pendant que le processus est en cours
  return <div>Connexion en cours avec Twitter...</div>;
};

export default TwitterRedirect;

async function callBackToRegisterUserInTheSystem(
  oauthToken,
  oauthVerifier,
  setToken,
  navigate
) {
  if (oauthToken && oauthVerifier) {
    // Appeler l'endpoint de callback du backend
    const url = `${process.env.REACT_APP_API_URL}/users/oauth2/twitter/twitter-callback?oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`;

    console.log('backed callback endPoint to  = ' + url);
    try {
      const response = await axios.get(url);
      console.log(
        'response.data.statusCode got from twitter-callback = ' +
          response.data.statusCode
      );
      console.log(
        'response.data.body.token got from twitter-callback = ' +
          response.data.body.token
      );
      console.log(
        'response.data.body.email got from twitter-callback = ' +
          response.data.body.email
      );

      if (response.data.statusCode === 200) {
        if (response.data.body.token) {
          console.log('statusCode OK and token found for the twitter user');
          setToken(response.data.body.token, response.data.body.email);
          navigate('/');
        }
      } else {
        console.log(i18n.t('page.login.twitterSignInProblem'));
      }
    } catch (error) {
      console.error('Erreur lors de la connexion avec Twitter:', error);
      navigate('/auth/login', {
        state: { error: 'Connexion échouée avec Twitter.' },
      });
    }
  }
}
