import { View } from 'app-studio';

export const FormItem = (props: any) => (
  <View
    width={'40vw'}
    media={{ mobile: { width: '100%' } }}
    maxWidth={'100%'}
    {...props}
  />
);
