function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="question-mark-circle" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector_25"
            d="M18,10c0,2.122 -0.843,4.157 -2.343,5.657c-1.5,1.5 -3.535,2.343 -5.657,2.343c-2.122,0 -4.157,-0.843 -5.657,-2.343c-1.5,-1.5 -2.343,-3.535 -2.343,-5.657c0,-2.122 0.843,-4.157 2.343,-5.657c1.5,-1.5 3.535,-2.343 5.657,-2.343c2.122,0 4.157,0.843 5.657,2.343c1.5,1.5 2.343,3.535 2.343,5.657zM10,7c-0.176,0 -0.348,0.046 -0.501,0.134c-0.152,0.088 -0.278,0.214 -0.366,0.366c-0.063,0.118 -0.15,0.222 -0.254,0.306c-0.105,0.084 -0.225,0.146 -0.353,0.182c-0.129,0.037 -0.264,0.047 -0.397,0.03c-0.133,-0.016 -0.261,-0.059 -0.377,-0.126c-0.116,-0.067 -0.217,-0.157 -0.298,-0.263c-0.08,-0.107 -0.139,-0.229 -0.171,-0.359c-0.033,-0.13 -0.039,-0.265 -0.019,-0.397c0.021,-0.132 0.068,-0.259 0.138,-0.373c0.33,-0.572 0.84,-1.019 1.45,-1.272c0.61,-0.252 1.287,-0.297 1.924,-0.126c0.638,0.171 1.202,0.548 1.604,1.072c0.402,0.524 0.62,1.166 0.62,1.826c0,0.621 -0.192,1.226 -0.55,1.733c-0.358,0.507 -0.865,0.89 -1.45,1.097v0.17c0,0.265 -0.105,0.52 -0.293,0.707c-0.187,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293c-0.188,-0.187 -0.293,-0.442 -0.293,-0.707v-1c0,-0.265 0.105,-0.52 0.293,-0.707c0.187,-0.188 0.442,-0.293 0.707,-0.293c0.265,0 0.52,-0.105 0.707,-0.293c0.188,-0.187 0.293,-0.442 0.293,-0.707c0,-0.265 -0.105,-0.52 -0.293,-0.707c-0.187,-0.188 -0.442,-0.293 -0.707,-0.293zM10,15c0.265,0 0.52,-0.105 0.707,-0.293c0.188,-0.187 0.293,-0.442 0.293,-0.707c0,-0.265 -0.105,-0.52 -0.293,-0.707c-0.187,-0.188 -0.442,-0.293 -0.707,-0.293c-0.265,0 -0.52,0.105 -0.707,0.293c-0.188,0.187 -0.293,0.442 -0.293,0.707c0,0.265 0.105,0.52 0.293,0.707c0.187,0.188 0.442,0.293 0.707,0.293z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
