import { View, Input, Image, Span, useMount } from 'app-studio';
import { Center } from '@app-studio/web';
import Lottie from 'lottie-react';

import Number0 from './json/number-0.json';
import Number1 from './json/number-1.json';
import Number2 from './json/number-2.json';
import Number3 from './json/number-3.json';
import Number4 from './json/number-4.json';
import Number5 from './json/number-5.json';
import Number6 from './json/number-6.json';
import Number7 from './json/number-7.json';
import Number8 from './json/number-8.json';
import Number9 from './json/number-9.json';
import { useEffect, useState, useRef } from 'react';

function GridLayout({ number }) {
  const stringNumber = number.split('').reverse().join('');

  return (
    <ContainerWithImage>
      <Center>
        <ContainerWithTexts>
          <LottieNumber number={stringNumber[8]} />
          <LottieNumber number={stringNumber[7]} />
          <LottieNumber number={stringNumber[6]} />
        </ContainerWithTexts>
        <ContainerWithText>
          <MainHeading>.</MainHeading>
        </ContainerWithText>
        <ContainerWithTexts>
          <LottieNumber number={stringNumber[5]} />
          <LottieNumber number={stringNumber[4]} />
          <LottieNumber number={stringNumber[3]} />
        </ContainerWithTexts>
        <ContainerWithText>
          <MainHeading>.</MainHeading>
        </ContainerWithText>
        <ContainerWithTexts>
          <LottieNumber number={stringNumber[2]} />
          <LottieNumber number={stringNumber[1]} />
          <LottieNumber number={stringNumber[0]} />
        </ContainerWithTexts>
      </Center>
    </ContainerWithImage>
  );
}

export default GridLayout;

const LottieNumber = ({ number }) => {
  const [segment, setSegment] = useState([0, 10]);
  const [numtoShow, setNumtoShow] = useState(number);
  const lottieRef = useRef();
  const numbers = {
    0: Number0,
    1: Number1,
    2: Number2,
    3: Number3,
    4: Number4,
    5: Number5,
    6: Number6,
    7: Number7,
    8: Number8,
    9: Number9,
  };

  useEffect(() => {
    if (lottieRef.current && numtoShow != number) {
      setSegment([15, 40]);
      lottieRef.current.play();
    }
  }, [number]);

  return (
    <NumberContainer>
      <Lottie
        style={{ width: '100%' }}
        onLoadedImages={() => {}}
        onComplete={() => {
          if (numtoShow != number) {
            setSegment([0, 15]);
            setNumtoShow(number);
            lottieRef.current.play();
          }
        }}
        initialSegment={segment}
        loop={false}
        autoplay={true}
        lottieRef={lottieRef}
        animationData={numtoShow != undefined ? numbers[numtoShow] : Number0}
      />
    </NumberContainer>
  );
};
const NumberContainer = (props) => (
  <View
    backgroundImage={'linear-gradient(to bottom, #8467CA, #391989)'}
    paddingVertical={10}
    borderRadius={5}
    width={54}
    media={{ mobile: { width: 18 } }}
    {...props}
  />
);

const ContainerWithImage = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flexDirection={'row'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    paddingVertical={50}
    media={{ mobile: { paddingTop: 20, paddingBottom: 20 } }}
    {...props}
  />
);

const ContainerWithText = (props) => (
  <View flex={'0 0 auto'} paddingTop={24} {...props} />
);
const MainHeading = (props) => (
  <View
    font={'600 56px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    media={{ mobile: { paddingBottom: 30 } }}
    {...props}
  />
);

const ContainerWithTexts = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={16}
    alignItems={'flex-start'}
    justifyContent={'flex-start'}
    {...props}
  />
);
