import { useCallback, useState, forwardRef } from 'react';
import './style.css';

function UserCard() {
  return (
    <div className={`email-sent-container2`}>
      <div className={`email-sent-container1`}>
        <img className={`image-container img-content-37065d85`} />
      </div>
    </div>
  );
}

export default UserCard;
