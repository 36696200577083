import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import {
  Button,
  Horizontal,
  Left,
  FormikForm,
  FormikTextField,
  FormikSelect,
  FormikSwitch,
  hideModal,
  Vertical,
} from '@app-studio/web';
import * as Yup from 'yup';
import { ServiceService } from 'src/services/api';
import { useAuthStore } from 'src/stores/AuthStore';
import { Label } from 'src/components/Label';
import { FormItem } from 'src/components/Form';

type ValuesForJoin = {
  slogan: string;
  anonymous: boolean;
  coordinates: {
    lon: number;
    lat: number;
  } | null;
};

const validationSchema = Yup.object().shape({
  slogan: Yup.string(),
  anonymous: Yup.boolean(),
});

const JoinForm = ({ onRefresh, protestID }) => {
  const { location, setLocation, user } = useAuthStore(
    ({ location, setLocation, user }) => ({
      location,
      setLocation,
      user,
    })
  );

  const { loading, run } = ServiceService.useJoinProtest({
    onSuccess: (res) => {
      if (onRefresh) onRefresh();
      hideModal();
    },
  });

  return (
    <Formik
      onSubmit={async (values: ValuesForJoin) => {
        try {
          if (!location) {
            const loc = await getLocation();
            setLocation(loc);
            values.coordinates = loc;
          }
        } catch (e) {
          console.warn(e);
        }
        run(protestID, values);
      }}
      validationSchema={validationSchema}
      initialValues={{
        slogan: '',
        anonymous: user.anonymous,
        coordinates: location,
      }}
    >
      {({ errors, handleSubmit }) => {
        return (
          <FormikForm>
            <Vertical gap={10}>
              <FormItem>
                <Label>{i18n.t('form.slogan.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.slogan.placeholder')}
                  name="slogan"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              <Horizontal>
                <FormikSwitch
                  placeholder={i18n.t('form.anonymous.placeholder')}
                  name="anonymous"
                  shape={'rounded'}
                  variant={'outline'}
                  label={i18n.t('form.anonymous.label')}
                  labelPosition="left"
                />
              </Horizontal>
            </Vertical>

            <Left marginVertical={20}>
              <Button
                isLoading={loading}
                type="submit"
                width={200}
                onClick={handleSubmit}
              >
                {i18n.t('button.join')}
              </Button>
            </Left>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default JoinForm;

function getLocation() {
  return new Promise<{ lon: number; lat: number }>((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          resolve(coords);
        },
        (error) => {
          reject(error);
        },
        { maximumAge: 10000, timeout: 5000 }
      );
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
}
