function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="signal 1_2" xmlns="http://www.w3.org/2000/svg">
          <g id="Group_3">
            <path
              id="Vector_19"
              d="M4.1945,3.8181c-0.075,0.032 -0.276,0.206 -0.445,0.384c-1.331,1.416 -2.283,3.136 -2.747,4.969c-0.267,1.064 -0.323,1.547 -0.323,2.831c0,1.284 0.056,1.767 0.323,2.831c0.403,1.589 1.186,3.122 2.264,4.421c0.731,0.886 0.98,1.054 1.406,0.975c0.23,-0.043 0.511,-0.324 0.554,-0.554c0.061,-0.314 -0.005,-0.482 -0.338,-0.843c-1.823,-1.978 -2.733,-4.257 -2.733,-6.83c0,-2.583 0.905,-4.837 2.738,-6.839c0.211,-0.225 0.314,-0.38 0.337,-0.492c0.113,-0.614 -0.478,-1.097 -1.036,-0.853z"
              fill="currentColor"
            />
            <path
              id="Vector_20"
              d="M19.1955,3.8181c-0.193,0.084 -0.39,0.323 -0.427,0.52c-0.056,0.305 0.014,0.478 0.342,0.834c1.819,1.978 2.733,4.261 2.733,6.83c0,2.569 -0.914,4.852 -2.733,6.83c-0.333,0.361 -0.398,0.529 -0.337,0.843c0.042,0.23 0.323,0.511 0.553,0.554c0.361,0.07 0.492,0.004 0.933,-0.45c1.275,-1.308 2.259,-3.09 2.732,-4.936c0.272,-1.055 0.329,-1.552 0.329,-2.841c0,-1.289 -0.057,-1.786 -0.329,-2.841c-0.473,-1.847 -1.411,-3.543 -2.742,-4.959c-0.412,-0.441 -0.694,-0.544 -1.054,-0.384z"
              fill="currentColor"
            />
            <path
              id="Vector_21"
              d="M7.1475,6.6821c-0.389,0.131 -1.284,1.261 -1.706,2.147c-0.248,0.52 -0.445,1.096 -0.576,1.678c-0.094,0.426 -0.108,0.609 -0.108,1.495c0,0.886 0.014,1.069 0.108,1.495c0.131,0.582 0.328,1.158 0.576,1.678c0.431,0.905 1.36,2.063 1.739,2.161c0.478,0.127 0.928,-0.215 0.928,-0.707c0,-0.282 -0.037,-0.357 -0.389,-0.736c-0.15,-0.169 -0.389,-0.474 -0.525,-0.68c-0.665,-1.008 -0.961,-1.992 -0.961,-3.211c0,-1.219 0.296,-2.203 0.961,-3.211c0.136,-0.206 0.375,-0.511 0.525,-0.68c0.352,-0.379 0.389,-0.454 0.389,-0.74c0,-0.502 -0.482,-0.849 -0.961,-0.689z"
              fill="currentColor"
            />
            <path
              id="Vector_22"
              d="M16.4665,6.6581c-0.356,0.08 -0.576,0.356 -0.576,0.717c0,0.282 0.037,0.357 0.389,0.736c0.717,0.788 1.228,1.828 1.415,2.878c0.089,0.535 0.089,1.491 0,2.025c-0.187,1.05 -0.698,2.091 -1.415,2.879c-0.352,0.379 -0.389,0.454 -0.389,0.736c0,0.492 0.45,0.834 0.928,0.707c0.379,-0.098 1.308,-1.256 1.739,-2.161c0.248,-0.52 0.445,-1.096 0.576,-1.678c0.094,-0.426 0.108,-0.609 0.108,-1.495c0,-0.886 -0.014,-1.069 -0.108,-1.495c-0.286,-1.28 -0.904,-2.489 -1.72,-3.371c-0.412,-0.445 -0.623,-0.553 -0.947,-0.478z"
              fill="currentColor"
            />
            <path
              id="Vector_23"
              d="M11.4135,8.8381c-1.955,0.361 -3.131,2.428 -2.433,4.275c0.52,1.397 1.974,2.287 3.389,2.086c1.528,-0.216 2.611,-1.299 2.827,-2.827c0.192,-1.355 -0.638,-2.789 -1.931,-3.328c-0.61,-0.258 -1.219,-0.323 -1.852,-0.206zM12.3365,10.3191c0.361,0.08 0.643,0.234 0.891,0.502c0.267,0.286 0.389,0.515 0.459,0.89c0.104,0.521 -0.056,1.041 -0.45,1.463c-0.646,0.693 -1.682,0.731 -2.39,0.084c-0.45,-0.412 -0.647,-0.984 -0.535,-1.547c0.075,-0.384 0.197,-0.619 0.464,-0.895c0.244,-0.258 0.455,-0.38 0.816,-0.478c0.305,-0.08 0.431,-0.085 0.745,-0.019z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
