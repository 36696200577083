import { View, Image } from 'app-studio';

function PersonCard({ item, userEmail }) {
  if (userEmail != item.participantID) {
    console.log('userEmail != item.participantID');
    return (
      <CardContainer>
        <CircularImageImg src={item.avatar} />
        <ProfileCard>
          <NameTitle>{item.name}</NameTitle>
          <LocationText>{item.location}</LocationText>
        </ProfileCard>
        {item.slogan && <ClimateMarchMessage slogan={item.slogan} />}
      </CardContainer>
    );
  } else {
    // apply the CardContainer border color to #5534A5
    console.log('userEmail == item.participantID. Precise border');
    return (
      <CardContainer style={{ borderColor: '#5534A5' }}>
        <CircularImageImg src={item.avatar} />
        <ProfileCard>
          <NameTitle>{item.name}</NameTitle>
          <LocationText>{item.location}</LocationText>
        </ProfileCard>
        {item.slogan && <ClimateMarchMessage slogan={item.slogan} />}
      </CardContainer>
    );
  }
}

export default PersonCard;

function ClimateMarchMessage({ slogan }) {
  return (
    <MarchingDemand>
      <MarchDemandClimate>{slogan}</MarchDemandClimate>
    </MarchingDemand>
  );
}

const MarchingDemand = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    padding={20}
    marginTop={20}
    background={'url(/assets/message.png)    no-repeat 0 0 / cover'}
    {...props}
  />
);

const MarchDemandClimate = (props) => (
  <View
    display="flex"
    alignItems="center"
    margin={'10px 30px 30px 40px'}
    height={100}
    paddingTop={10}
    font={'600 14px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    textAlign={'center'}
    {...props}
  />
);

const CircularImageImg = ({ src }) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    src={src}
  />
);

const CardContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    paddingTop={19}
    paddingBottom={20}
    borderBottom={'1px solid #ededee'}
    {...props}
  />
);

const RoundedBox = (props) => (
  <View
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    width={50}
    height={50}
    background={'rgba(237, 237, 238, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={50}
    {...props}
  />
);

const ProfileCard = (props) => (
  <View flex={'0 0 auto'} marginTop={15} {...props} />
);
const NameTitle = (props) => (
  <View font={'500 20px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const LocationText = (props) => (
  <View
    marginTop={2}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);
