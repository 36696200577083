import { create } from 'zustand';
import * as AsyncStorage from 'src/utils/localstorage';
import { ServiceService, OpenAPI } from 'src/services/api';
import { setToken } from 'src/utils/request';
import { useAppStore } from './AppStore';

type AuthState = {
  isAuthentificated: boolean;
  token: boolean | string;
  device: boolean;
  user: any;
  location: { lon: number; lat: number } | null;
  setLocation: (location: any) => void;
  setToken: (token: string, email: string) => void;
  login: (params: any) => void;
  logout: () => void;
  refresh: () => void;
};

export const useAuthStore = create<AuthState>((set, get) => ({
  isAuthentificated: false,
  token: false,
  device: false,
  user: {},
  location: null,
  setLocation: (location) => set({ location }),
  setToken: async (token, email) => {
    try {
      if (token) {
        setToken(token);
        await AsyncStorage.write('@token', token);
        await AsyncStorage.write('@email', email);

        const storedToken = await AsyncStorage.read('@token');

        try {
          set({ token: storedToken, isAuthentificated: true });

          const response: any = await ServiceService.getService(email);

          OpenAPI.HEADERS = {
            Authorization: `Bearer ${token}`,
          };

          if (response.body) {
            set({ user: response.body });
          }

          console.log('response', response);
        } catch (e) {
          console.warn(e);
          set({ isAuthentificated: false, token: false });
        }
      } else {
        await AsyncStorage.remove('@token');
        await AsyncStorage.remove('@email');
        set({ token: false });
      }
      useAppStore.getState().setReady();
    } catch (error) {
      console.warn(error);
      set({ isAuthentificated: false, token: false });
      useAppStore.getState().setReady();
    }
  },
  refresh: async () => {
    const { email } = get().user;
    if (email) {
      try {
        const response: any = await ServiceService.getService(email);

        if (response.body) {
          set({ user: response.body });
        }

        console.log('response', response);
        useAppStore.getState().setReady();
      } catch (e) {
        console.warn(e);
        set({ isAuthentificated: false, token: false });
      }
    }
  },
  login: async (params) => {
    try {
      const { token } = await ServiceService.loginUser(params);
      set({ token, isAuthentificated: true });
    } catch (error) {
      console.warn(error);
      // Handle error
    }
  },
  logout: async () => {
    set({ isAuthentificated: false, token: false, user: {} });
    OpenAPI.HEADERS = {};
    await AsyncStorage.remove('@token');
    await AsyncStorage.remove('@email');
    return true;
  },
}));

export const isAuthentificated = () => {
  try {
    return useAuthStore.getState().isAuthentificated;
  } catch {}
  return false;
};

const token = await AsyncStorage.read('@token');
const email = await AsyncStorage.read('@email');
if (typeof token == 'string' && typeof email == 'string') {
  useAuthStore.getState().setToken(token, email);
} else {
  useAppStore.getState().setReady();
}
