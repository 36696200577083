import React from 'react';

import {
  Modal,
  Button,
  View,
  Vertical,
  hideModal,
  FileSVG,
  Right,
} from '@app-studio/web';
import { EventDate } from '../components/status';
import JoinForm from 'src/forms/JoinForm';

export const JoinModal = ({ event, onRefresh }: any) => {
  return (
    <Modal.Container>
      <Modal.Body>
        <Right width={20} onClick={hideModal}>
          <FileSVG src={'/assets/close.svg'} />
        </Right>
        <ProtestHeroContainer>
          <CauseImage url={event.illustration} />
          <HeroSection>
            <BlackTag>the cause</BlackTag>
            <OxygenText>{event.title}</OxygenText>
            <EventDate event={event} />
            <JoinForm protestID={event.protestID} onRefresh={onRefresh} />
          </HeroSection>
        </ProtestHeroContainer>
      </Modal.Body>
    </Modal.Container>
  );
};

const ProtestHeroContainer = (props) => (
  <View padding={20} overflowY="auto" maxHeight="90vh" {...props} />
);

const CauseImage = (props) => (
  <View
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    width={'100%'}
    height={250}
    background={' url(' + props.url + ')'}
    backgroundRepeat={'no-repeat, no-repeat'}
    backgroundSize={'cover'}
    borderRadius={8}
    marginBottom={20}
    {...props}
  />
);

const HeroSection = (props) => (
  <Vertical
    alignItems={'flex-start'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const BlackTag = (props) => (
  <View
    backgroundColor="color.black"
    color="color.white"
    padding={10}
    borderRadius={4}
    width={'fit-content'}
    marginBottom={20}
    {...props}
  />
);

const BlackButton = (props) => (
  <Button
    padding={16}
    height={48}
    backgroundImage={'linear-gradient(to bottom, #8467CA, #391989)'}
    color="color.white"
    borderRadius={5}
    marginVertical={20}
    {...props}
  />
);

const OxygenText = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={'-6px'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    textAlign={'left'}
    {...props}
  />
);
