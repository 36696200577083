import { View } from 'app-studio';
import { Horizontal, Left, Right } from '@app-studio/web';
import LoginForm from '../../forms/LoginForm';
import { useNavigate } from 'react-router-dom';
import i18n from 'src/utils/locale';
import { BackToHomeButton } from 'src/components/Button';

function AuthContainer() {
  const navigate = useNavigate();

  return (
    <LoginContainer>
      <BackToHomeButton />
      <WelcomeMessage>{i18n.t('page.login.welcome')}</WelcomeMessage>
      <LoginForm />
      <Horizontal margin={10}>
        <Left>
          <Horizontal onClick={() => navigate('/auth/register')}>
            <PasswordLabel>{i18n.t('page.login.haveAccount')}</PasswordLabel>
            <SignUpLink>{i18n.t('page.login.signUp')}</SignUpLink>
          </Horizontal>
        </Left>
        {/* <Right onClick={() => navigate('/security/reset-password')}>
          <PasswordLabel>{i18n.t('page.login.forgotPassword')}</PasswordLabel>
        </Right> */}
      </Horizontal>
    </LoginContainer>
  );
}

export default AuthContainer;

const WelcomeMessage = (props) => (
  <View
    font={'600 32px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    margin={50}
    textAlign="center"
    {...props}
  />
);

const LoginContainer = (props) => (
  <View
    paddingTop={20}
    flexDirection={'column'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const AccountActions = (props) => (
  <View
    display={'flex'}
    flexDirection={'row'}
    gap={8}
    marginTop={16}
    {...props}
  />
);

const PasswordLabel = (props) => (
  <View font={'400 16px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const SignUpLink = (props) => (
  <View
    font={'500 16px Work Sans'}
    color={'rgba(85, 52, 165, 1)'}
    textDecorationLine={'underline'}
    marginLeft={10}
    {...props}
  />
);
