import { View, Image } from 'app-studio';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import moment from 'src/utils/moment';

function ParticipantDashboard({ data }) {
  if (!data || !data.participantsMeasurements) return null;
  const start = moment(data.startDate);

  const chartDat = data.participantsMeasurements.map((row, index) => ({
    name:
      parseInt(
        moment.duration(moment(row.timestamp).diff(start)).as('minutes')
      ).toString() + ' mins',
    participants: row.count,
  }));

  return (
    <View
      width="100%"
      height={400}
      media={{
        mobile: {
          height: 200,
        },
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={'100%'}
          height={'100%'}
          data={chartDat}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="participants"
            stroke="#5534A5"
            fill="rgba(85, 52, 165, 0.30)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </View>
  );
}

export default ParticipantDashboard;
