import { View, Image, Span, useMount } from 'app-studio';
import { Button, FileSVG, Horizontal, showModal } from '@app-studio/web';
import { EventDate } from '../../../../components/status';
import ParticipantList from '../../../../components/ParticipantList';
import i18n from 'src/utils/locale';
import { ServiceService } from 'src/services/api';
import { useState } from 'react';
import { isAuthentificated } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import megaphoneImage from '../../../../assets/images/megaphone2_70x70.gif';

function EventHero({
  data,
  count,
  items,
  status,
  onRefresh,
  participating,
}: any) {
  const [showPastille, setShowPastille] = useState(false);

  const navigate = useNavigate();
  const confirmPressence = ServiceService.useConfirmProtestPresenceService({
    onSuccess: () => {
      onRefresh();
    },
  });
  const leaveProtest = ServiceService.useLeaveProtestService({
    onSuccess: () => {
      onRefresh();
    },
  });

  useMount(() => {
    const interval = setInterval(() => {
      setShowPastille(true);
    }, 10 * 60 * 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  });

  return (
    <ProtestHeroContainer>
      <CauseImage url={data.body.illustration} />
      <HeroSection>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={megaphoneImage}
            alt="Organizer"
            style={{ marginRight: '10px' }}
          />
          <BlackTag
            style={{ marginBottom: 'inherit', textTransform: 'uppercase' }}
          >
            {data.body.organizer || 'untty'}
          </BlackTag>
        </div>
        <OxygenText>{data.body.title}</OxygenText>
        <EventDate event={data.body} showRemainTime={true} />
        {items && items.body?.slice && count && (
          <ParticipantList items={items.body.slice(0, 4)} total={count} />
        )}

        {(!participating || !isAuthentificated()) && status != 'ended' && (
          <Horizontal justifyContent="space-between" gap={10}>
            <BlackButton
              onClick={() => {
                if (isAuthentificated())
                  showModal('JoinModal', {
                    event: data.body,
                    onRefresh,
                  });
                else {
                  navigate('/auth/login');
                }
              }}
            >
              {i18n.t('button.join')}
            </BlackButton>
          </Horizontal>
        )}
        {isAuthentificated() && participating && status != 'ended' && (
          <Horizontal justifyContent="space-between" gap={10}>
            <BlackButton
              isLoading={leaveProtest.loading}
              onClick={() => {
                leaveProtest.run(data.body.protestID);
              }}
              isAuto
            >
              {i18n.t('button.leave')}
            </BlackButton>
            <GrayButton
              onClick={() => {
                confirmPressence.run(data.body.protestID);
                setShowPastille(false);
              }}
              isLoading={confirmPressence.loading}
              leftIcon={<FileSVG src={'/assets/load.svg'} />}
              isAuto
            >
              {i18n.t('button.here')} {showPastille && <Pastille />}
            </GrayButton>
          </Horizontal>
        )}
      </HeroSection>
    </ProtestHeroContainer>
  );
}

export default EventHero;

const Pastille = (props) => (
  <View
    size={10}
    backgroundColor={'#EA4335'}
    position="absolute"
    borderRadius={10}
    right={-5}
    top={-5}
    {...props}
  />
);

const ProtestHeroContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={10}
    padding={20}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    media={{ mobile: { flexDirection: 'column', padding: 10 } }}
    {...props}
  />
);

const CauseImage = (props) => (
  <View
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    width={'40vw'}
    height={350}
    background={' url(' + props.url + ')'}
    backgroundRepeat={'no-repeat, no-repeat'}
    backgroundSize={'cover'}
    borderRadius={8}
    marginRight={25}
    backgroundPosition={'center center'}
    media={{ mobile: { width: '100%' } }}
    {...props}
  />
);

const HeroSection = (props) => (
  <View
    display={'flex'}
    flex={'0 1 auto'}
    flexDirection={'column'}
    alignItems={'flex-start'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const BlackTag = (props) => (
  <View
    backgroundColor="color.black"
    color="color.white"
    padding={10}
    borderRadius={4}
    width={'fit-content'}
    marginBottom={20}
    {...props}
  />
);

const BlackButton = (props) => (
  <Button
    padding={16}
    height={48}
    backgroundImage={'linear-gradient(to bottom, #8467CA, #391989)'}
    color="color.white"
    borderRadius={5}
    isAuto
    media={{
      mobile: {
        height: 32,
        fontSize: 14,
      },
    }}
    {...props}
  />
);

const GrayButton = (props) => (
  <Button
    backgroundColor="color.white"
    border="1px solid gray"
    color="gray"
    height={50}
    isAuto
    media={{
      mobile: {
        height: 32,
        fontSize: 14,
      },
    }}
    {...props}
  />
);

const OxygenText = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={'-6px'}
    font={'600 32px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    textAlign={'left'}
    media={{
      mobile: {
        fontSize: 26,
      },
    }}
    {...props}
  />
);
