import { AlertModal } from 'src/modals/AlertModal';
import { LogoutModal } from 'src/modals/LogoutModal';
import { JoinModal } from 'src/modals/JoinModal';

const modalList: any = {
  AlertModal,
  LogoutModal,
  JoinModal,
};

export default modalList;
