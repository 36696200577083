import React from 'react';
import HomePage from 'src/pages/HomePage/home.page';
import NavigationPage from 'src/pages/navigation.page';

import JoinModal from 'src/pages/JoinModal';
import AccountPage from 'src/pages/AccountPage';
import RegisterPage from 'src/pages/RegisterPage';
import PasswordChangePage from 'src/pages/PasswordChangePage';
import EventPage from 'src/pages/EventPage';
import ResetPassword from 'src/pages/ResetPassword';
import LoginPage from 'src/pages/LoginPage';
import UserConfirmationPage from 'src/pages/UserConfirmationPage';
import GoogleRedirect from 'src/components/GoogleRedirect';
import TwitterRedirect from 'src/components/TwitterRedirect';

export const RouterConfig = [
  { name: 'home', path: '/', element: <HomePage /> },
  { name: 'join', path: '/modal/join', element: <JoinModal /> },
  { name: 'account', path: '/account', element: <AccountPage /> },
  { name: 'login', path: '/auth/login', element: <LoginPage /> },
  { name: 'login', path: '/auth/register', element: <RegisterPage /> },
  {
    name: 'password-change',
    path: '/security/password-change',
    element: <PasswordChangePage />,
  },
  {
    name: 'reset-password',
    path: '/security/reset-password',
    element: <ResetPassword />,
  },
  {
    name: 'confirmation',
    path: '/security/confirmation',
    element: <UserConfirmationPage />,
  },
  { name: 'navigation', path: '/nav', element: <NavigationPage /> },

  {
    path: '/event/:id',
    exact: true,
    element: <EventPage />,
  },
  {
    name: 'oauth2callback',
    path: '/oauth2/callback',
    element: <GoogleRedirect />,
  },

  {
    name: 'oauth2callback-twitter',
    path: '/oauth2/callback-twitter',
    element: <TwitterRedirect />,
  },
];
