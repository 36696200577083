function SvgIcon2({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
    >
      <g id="IONIcon/P/person/default" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector_24"
          d="M16.10995,6.7505v-0.0001c0.09,-1.2459 -0.3,-2.3792 -1.07,-3.2132c-0.76,-0.8238 -1.83,-1.2868 -3.04,-1.2868c-1.22,0 -2.29,0.4619 -3.05,1.2779zM16.10995,6.7505c-0.19,2.5722 -2.12,4.4999 -4.11,4.4999c-1.99,0 -3.93,-1.9286 -4.11,-4.4997zM7.88995,6.7504c-0.09,-1.2631 0.3,-2.3981 1.06,-3.2221z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          id="Vector_25"
          d="M20.25995,21.7494v0.0001c0.1,0.0013 0.21,-0.0207 0.3,-0.0644c0.1,-0.0437 0.18,-0.108 0.25,-0.1883v-0.0008c0.15,-0.1795 0.23,-0.447 0.17,-0.7498v-0.0004c-0.36,-1.9839 -1.47,-3.6545 -3.25,-4.8409zM20.25995,21.7494h-0.01M20.25995,21.7494h-0.01M20.24995,21.7494h-16.5v0M20.24995,21.7494h-16.5M3.74995,21.7494l-0.01,0.0001M3.74995,21.7494l-0.01,0.0001M3.73995,21.7495c-0.1,0.0014 -0.21,-0.0206 -0.3,-0.0643c-0.1,-0.0436 -0.18,-0.1079 -0.25,-0.1881v-0.0006M3.73995,21.7495l-0.55,-0.253M3.18995,21.4965c-0.15,-0.1795 -0.23,-0.447 -0.17,-0.7499v-0.0004M3.18995,21.4965l-0.17,-0.7503M3.01995,20.7462c0.35,-1.9838 1.47,-3.6544 3.25,-4.8414zM6.26995,15.9047c1.59,-1.06 3.62,-1.6553 5.73,-1.6553c2.11,0 4.14,0.5958 5.73,1.6553z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default SvgIcon2;
