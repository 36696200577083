import { View, useMount } from 'app-studio';
import './style.css';
import { useParams } from 'react-router';
import { ServiceService } from 'src/services/api';
import { Center, Vertical } from '@app-studio/web';
import GridLayout from './components/Counter';
import EventHero from './components/EventHero';
import ProtestDetails from './components/ProtestDetails';
import ParticipantList from './components/ParticipantList';
import ParticipantTrend from './components/ParticipantTrend';
import MapParticipant from './components/Map';
import { WS_URL } from 'src/configs/AppConfig';
import i18n from 'src/utils/locale';
import { getStatus } from 'src/utils/event';
import { useEffect, useState } from 'react';
import { BackToHomeButton } from 'src/components/Button';
import ShareButton from 'src/components/shareButton/ShareButton';

function EventPage({}) {
  const { id }: any = useParams();
  const [count, setCount] = useState(0);
  const [participantList, setParticipantList] = useState<any[]>([]);
  const getProtest = ServiceService.useGetService5({
    onSuccess({ body }) {
      if (body) {
        const status = getStatus(body);

        if (status == 'ongoing') {
          getProtestActiveCount.run(id);
        } else {
          getProtestCount.run(id);
        }
      }
    },
  });
  const getProtestCount = ServiceService.useGetService6({
    onSuccess({ body }) {
      if (body.count) {
        setCount(body.count);
      }
    },
  });

  const getProtestActiveCount = ServiceService.useOptionsServiceService({
    onSuccess({ body }) {
      if (body.count) {
        setCount(body.count);
      }
    },
  });
  const getProstestParticipation = ServiceService.useGetServiceService({
    cached: false,
  });
  const getProtestParticipants = ServiceService.useGetProtestParticipants();

  useMount(() => {
    const ws = new WebSocket(WS_URL);

    ws.addEventListener('error', console.error);

    ws.addEventListener('open', function open() {
      console.log('open');
      // ws.send('something');
    });

    ws.addEventListener('message', function message(event) {
      console.log(event);
      const message = JSON.parse(event.data);

      // Gérer les messages de type "ping"
      if (message.type === 'ping') {
        console.log('Ping received from server');
        // Envoyer un message de type "pong" en réponse. Mais il me semble que ce n'est pas obligatoire
        ws.send(JSON.stringify({ type: 'pong' }));
      } else if (
        message.statusCode === 200 &&
        message.body &&
        message.body.count &&
        message.body.protestID &&
        message.body.protestID == id
      ) {
        // Gérer les autres types de messages
        setCount(message.body.count);
      }
    });

    return () => {
      ws.close();
    };
  });

  //get live information when count update
  useEffect(() => {
    getProtest.run(id);
    getProtestParticipants.run(id);
    getProstestParticipation.run(id);
  }, [count]);

  //Get only the active participants list
  useEffect(() => {
    if (
      !getProtestParticipants.data ||
      !getProtestParticipants.data.body.filter
    )
      return;
    const newParticipantsList = getProtestParticipants.data.body.filter(
      (e) => e.active === 'true'
    );
    setParticipantList([...newParticipantsList]);
  }, [getProtestParticipants.data]);

  let status = 'ended';

  if (getProtest.data) {
    status = getStatus(getProtest.data.body);
  }

  return (
    <EventContainer>
      <TopBar>
        <BackToHomeButton />
      </TopBar>
      <ProtestCardContainer>
        <ProtestCard>
          <MainHeading>{i18n.t('page.event.status.' + status)}:</MainHeading>
          <Center>
            <GridLayout number={count.toString()} />
          </Center>
        </ProtestCard>
      </ProtestCardContainer>
      {getProtest.data && (
        <>
          <EventHero
            data={getProtest.data}
            count={count}
            items={getProtestParticipants.data}
            participating={
              getProstestParticipation.data &&
              getProstestParticipation.data.body &&
              getProstestParticipation.data.body.active
            }
            status={status}
            onRefresh={() => {
              getProstestParticipation.run(id);
              getProtestParticipants.run(id);
            }}
          />
          <ShareButton
            url={window.location.href}
            title={
              i18n.t('page.event.quoteSocialShare') +
              '"' +
              getProtest.data.body.title +
              '" ' +
              i18n.t('page.event.quoteSocialSharePart2')
            }
          />
          <div
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              flexWrap: 'wrap',
              border: `${window.innerWidth >= 768 ? '1px solid #5534A5' : ''}`,
              padding: 20,
              borderRadius: 8,
            }}
          >
            <div style={{ width: window.innerWidth >= 768 ? '48%' : '100%' }}>
              <ProtestDetails data={getProtest.data} />{' '}
            </div>

            <div
              style={{
                width: window.innerWidth >= 768 ? '48%' : '100%',
                height: window.innerWidth >= 768 ? '50vh' : '30vh',
              }}
            >
              <MapParticipant items={participantList} />
            </div>
          </div>

          <ParticipantList
            id={id}
            participating={
              getProstestParticipation.data &&
              getProstestParticipation.data.body &&
              getProstestParticipation.data.body.active
            }
          />
          <ParticipantTrend data={getProtest.data.body} />
        </>
      )}
    </EventContainer>
  );
}

export default EventPage;

const MainHeading = (props) => (
  <View
    paddingTop={30}
    paddingHorizontal={30}
    font={'600 32px Work Sans'}
    media={{
      mobile: {
        fontSize: 20,
        paddingTop: 40,
        paddingLeft: 20,
        paddingRight: 20,
      },
    }}
    color={'rgba(255, 255, 255, 1)'}
    {...props}
  />
);

const EventContainer = (props) => <Vertical width={'100%'} {...props} />;

const ProtestCardContainer = (props) => (
  <View
    width={'100%'}
    zIndex={3}
    top={'13%'}
    left={0}
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  />
);

const ProtestCard = (props) => (
  <Vertical
    width={'100%'}
    backgroundColor={'#5534A5'}
    borderRadius={8}
    marginVertical={20}
    overflow={'hidden'}
    backgroundImage={'url(/assets/circle.svg)'}
    backgroundRepeat={'no-repeat'}
    backgroundSize={'400px 400px 200px'}
    {...props}
  />
);

const TopBar = (props) => (
  <View
    padding={10}
    marginBottom={20}
    display={'flex'}
    justifyContent={'space-between'}
    alignItems="center"
    width={'100%'}
    {...props}
  />
);
