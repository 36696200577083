function SvgIcon1({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <g id="calendar_2" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Icon_2"
          d="M15.375,7.583h-12.75M11.835,1.917v2.833M6.175,1.917v2.833M6.025,16.083h5.95c1.2,0 1.79,0 2.24,-0.231c0.4,-0.204 0.73,-0.529 0.93,-0.929c0.23,-0.455 0.23,-1.05 0.23,-2.24v-5.95c0,-1.19 0,-1.785 -0.23,-2.239c-0.2,-0.4 -0.53,-0.725 -0.93,-0.929c-0.45,-0.232 -1.04,-0.232 -2.23,-0.232h-5.95c-1.2,0 -1.79,0 -2.24,0.232c-0.4,0.204 -0.73,0.529 -0.93,0.929c-0.24,0.454 -0.24,1.049 -0.24,2.239v5.95c0,1.19 0,1.785 0.24,2.24c0.2,0.4 0.53,0.725 0.93,0.929c0.45,0.231 1.04,0.231 2.23,0.231z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        />
      </g>
    </svg>
  );
}

export default SvgIcon1;
