import { View } from 'app-studio';

export const Label = (props) => (
  <View
    marginTop={20}
    marginBottom={5}
    font={'400 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);
