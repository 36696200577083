import React from 'react';
import { View as $View, ViewProps } from 'app-studio';
import { ColorNameProp } from 'src/types/color';

export interface Vie extends Omit<ViewProps, 'backgroundColor' | 'color'> {
  backgroundColor: ColorNameProp;
}

export const View = (props: any) => <$View {...props} />;

export const Horizontal = (props: any) => (
  <View display={'flex'} flexDirection="row" {...props} />
);
export const HorizontalScroll = (props: any) => (
  <Horizontal overflowX="auto" {...props} />
);

export const Vertical = (props: any) => (
  <View display={'flex'} flexDirection="column" {...props} />
);

export const VerticalScroll = (props: any) => (
  <Vertical overflowY="auto" {...props} />
);

export const Inline = (props: any) => (
  <View display={'inline-flex'} wordBreak="break-word" {...props} />
);

export const Center = (props: any) => (
  <View
    display={'flex'}
    justifyContent="center"
    alignItems={'center'}
    {...props}
  />
);

export const Top = (props: any) => <View marginBottom="auto" {...props} />;
export const Bottom = (props: any) => <View marginTop="auto" {...props} />;

export const Left = (props: any) => <View marginRight="auto" {...props} />;
export const Right = (props: any) => <View marginLeft="auto" {...props} />;
