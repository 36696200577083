import { View } from 'app-studio';
import ImageUploader from '../ImageUploader';
import UserForm from 'src/forms/AccountForm';
import { Horizontal, Right, Button, Vertical } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import i18n from 'src/utils/locale';

function AccountSection() {
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  return (
    <AccountInfoContainer>
      <Horizontal>
        <ProtestHeading>{i18n.t('page.account.info')}</ProtestHeading>
      </Horizontal>
      <ProfileForm>
        <ImageContainer1>
          <Horizontal>
            <Vertical>
              <ImageUploader />
              <UploadImageText>{i18n.t('button.uploadImage')}</UploadImageText>
            </Vertical>
            <Right>
              <Button
                onClick={() => {
                  logout();
                  navigate('/');
                }}
              >
                {i18n.t('action.logout')}
              </Button>
            </Right>
          </Horizontal>
          <ProtestForm>
            <UserForm />
          </ProtestForm>
        </ImageContainer1>
      </ProfileForm>
    </AccountInfoContainer>
  );
}

export default AccountSection;

const ProtestForm = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={40}
    {...props}
  />
);

const AccountInfoContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={32}
    {...props}
  />
);

const ProtestHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const ProfileForm = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    padding={'20px 19.5px'}
    marginTop={20}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);

const ImageContainer1 = (props) => <View flex={'0 0 auto'} {...props} />;

const UploadImageText = (props) => (
  <View
    marginTop={16}
    font={'400 16px Work Sans'}
    color={'rgba(85, 52, 165, 1)'}
    textDecorationLine={'underline'}
    width={150}
    textAlign="center"
    {...props}
  />
);
