function SvgIcon2({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="clock_2" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector_24"
            d="M12,8v4l3,3M21,12c0,1.182 -0.233,2.352 -0.685,3.444c-0.452,1.092 -1.115,2.084 -1.951,2.92c-0.836,0.836 -1.828,1.499 -2.92,1.951c-1.092,0.452 -2.262,0.685 -3.444,0.685c-1.182,0 -2.352,-0.233 -3.444,-0.685c-1.092,-0.452 -2.084,-1.115 -2.92,-1.951c-0.836,-0.836 -1.499,-1.828 -1.951,-2.92c-0.452,-1.092 -0.685,-2.262 -0.685,-3.444c0,-2.387 0.948,-4.676 2.636,-6.364c1.688,-1.688 3.977,-2.636 6.364,-2.636c2.387,0 4.676,0.948 6.364,2.636c1.688,1.688 2.636,3.977 2.636,6.364z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.3"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon2;
