import store from 'store';

export default {
  save: (key: string, data: any) => {
    // localStorage.setItem(USER_KEY,JSON.stringify(user))
    store.set(key, data);
  },
  get: (key: string) => {
    // return JSON.parse(localStorage.getItem(USER_KEY)||'{}')
    return store.get(key) || {};
  },
  remove: (key: string) => {
    // localStorage.removeItem(USER_KEY)
    store.remove(key);
  },
};
export const localStorageKey = {
  TOKEN: 'TOKEN',
};
