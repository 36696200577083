import { Modal, Button } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { hideModal } from '@app-studio/web';

export const LogoutModal = ({}) => {
  const { logout } = useAuthStore(({ logout }) => ({
    logout,
  }));
  return (
    <Modal.Container>
      <Modal.Header>Logout</Modal.Header>
      <Modal.Body>Cras mattis consectetur purus sit amet fermentum.</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => hideModal()}>Cancel</Button>
        <Button
          onClick={() => {
            logout();
            hideModal();
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal.Container>
  );
};
