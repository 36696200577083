import { View, Image } from 'app-studio';
import { Horizontal, Text } from '@app-studio/web';

import ParticipantList from '../ParticipantList';
import { EventDate, EventStatusTag } from '../status';
import { useNavigate } from 'react-router';

function EventCard({ event }) {
  const navigate = useNavigate();
  return (
    <EventContainer
      onClick={() => {
        navigate('/event/' + event.protestID);
      }}
    >
      <ImageContainer src={event.illustration} />
      <EventContent>
        <Horizontal>
          <TitleContainer>
            <TitleText>TITLE</TitleText>
          </TitleContainer>
          <EventStatusTag event={event} />
        </Horizontal>
        <EventTitle>{event.title}</EventTitle>
        <EventDate event={event} />
      </EventContent>
      <ParticipantList
        items={event.firstFourParticipants}
        total={event.participantsCount}
      />
    </EventContainer>
  );
}

export default EventCard;

const EventContent = (props) => <View height={'100%'} {...props} />;

const EventContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    gap={16}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    paddingTop={10}
    paddingBottom={5}
    {...props}
  />
);

const ImageContainer = ({ src }) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    flex={'0 0 auto'}
    width={259}
    maxWidth={'initial'}
    height={144}
    border={'none'}
    borderRadius={8}
    objectFit={'cover'}
    content={'url("' + src + '")'}
  />
);

const TitleContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingRight={6}
    paddingLeft={6}
    background={'rgba(85, 52, 165, 1)'}
    borderRadius={2}
    {...props}
  />
);

const TitleText = (props) => (
  <Text
    isTruncated
    maxWidth={200}
    flex={'0 0 auto'}
    font={'500 14px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    {...props}
  />
);

const EventTitle = (props) => (
  <Text
    isTruncated
    flex={'0 0 auto'}
    marginTop={'8px'}
    font={'500 20px/120.00000762939453% Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    textAlign={'left'}
    height={100}
    line={4}
    {...props}
  />
);
