import { create } from 'zustand';

export interface AppState {
  ready: boolean;
  started: boolean;
  loaded: boolean;
  initialUrl: string | null;
  redirectTo: string | null;
  setStarted: () => void;
  setLoaded: () => void;
  setReady: () => void;
  setRedirectTo: (redirectTo: string | null) => void;
  setRedirectOnHome: (redirectOnHome: string | null) => void;
}

export const useAppStore = create<AppState>((set) => ({
  ready: false,
  started: false,
  loaded: false,
  redirectTo: null,
  initialUrl: null,
  redirectOnHome: null,
  setStarted: () => {
    set((state) => ({
      ...state,
      started: true,
    }));
  },
  setLoaded: () => {
    set((state) => ({
      ...state,
      loaded: true,
    }));
  },
  setReady: () => {
    set((state) => ({
      ...state,
      ready: true,
    }));
  },
  setRedirectTo: (redirectTo: string | null | undefined) => {
    set((state) => ({
      ...state,
      redirectTo: state.ready ? null : redirectTo,
      initialUrl: null,
    }));
  },
  setRedirectOnHome: (redirectOnHome: any) => {
    set((state) => ({
      ...state,
      redirectOnHome,
    }));
  },
}));
