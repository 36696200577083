import React from 'react';
import { Helmet } from 'react-helmet';
import { Horizontal, View } from '@app-studio/web';
import TopBar from 'src/layouts/components/TopBar';
import LeftSide from 'src/layouts/components/LeftSide';

import { Modal } from '@app-studio/web';
import PageLayout from 'src/layouts/PageLayout';

import modals from 'src/modals';

import GlobalStyle from 'src/global.style';
import styled from 'src/utils/styled';
import { useAppStore } from 'src/stores/AppStore';
import { useLocation } from 'react-router';
import { APP_NAME } from 'src/configs/AppConfig';
import { useLocaleStore } from 'src/stores/LocaleStore';
import { useMount } from 'app-studio';

const AppLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const location = useLocation();

  const { ready } = useAppStore(({ ready }) => ({
    ready,
  }));

  const { language, initialize } = useLocaleStore(
    ({ language, initialize }) => ({
      language,
      initialize,
    })
  );

  useMount(initialize);

  const metaprops: any = {
    htmlAttributes: { lang: language },
    title: APP_NAME,
    meta: [
      { charset: 'UTF-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
    ],
    link: [
      {
        href: 'https://fonts.googleapis.com/css2?family=Work+Sans&display=swap',
        rel: 'stylesheet',
      },
    ],
  };

  const excludeForTopBar = [
    '/auth/login',
    '/auth/register',
    '/security/reset-password',
    '/security/confirmation',
    '/security/password-change',
  ];

  return (
    <>
      <Helmet {...metaprops} />
      {ready && (
        <View width="100vw" height={'100vh'} overflow="hidden">
          {!excludeForTopBar.includes(location.pathname) && <TopBar />}

          <Horizontal>
            {location.pathname.indexOf('/auth') >= 0 && <LeftSide />}
            <PageLayout
              width={location.pathname.indexOf('/auth') >= 0 ? '50vw' : '100%'}
            >
              {children}
            </PageLayout>
            <Modal.Layout modals={modals} />
          </Horizontal>
        </View>
      )}
      <FontStyle />
      <GlobalStyle />
    </>
  );
};

const FontStyle = styled.div`
  @font-face {
    font-family: 'MaterialIcons';
    src: url(${require('react-web-vector-icons/fonts/MaterialIcons.ttf')});
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url(${require('react-web-vector-icons/fonts/FontAwesome.ttf')});
  }

  @font-face {
    font-family: 'Entypo';
    src: url(${require('react-web-vector-icons/fonts/Entypo.ttf')});
  }
`;

export default AppLayout;
