import React, { PureComponent } from 'react';
import { View } from '@app-studio/web';

interface PageLayoutProps {
  children?: any;
  shouldIndex?: boolean;
  description?: string;
  title?: string;
  loading?: boolean;
  width?: string;
}

export class PageLayout extends PureComponent<PageLayoutProps> {
  render() {
    // const { children, shouldIndex, description, title } = this.props;

    return (
      <View
        height={'100vh'}
        overflow="hidden"
        {...this.props}
        media={{ mobile: { width: '100%' } }}
      >
        <View
          width="100%"
          height={'100vh'}
          backgroundColor="white"
          overflowY="scroll"
        >
          <View
            paddingHorizontal={40}
            media={{
              mobile: { paddingLeft: 20, paddingRight: 20 },
              tablet: { paddingLeft: 20, paddingRight: 20 },
            }}
            paddingTop={20}
            paddingBottom={100}
          >
            {this.props.children}
          </View>
        </View>
      </View>
    );
  }
}

export default PageLayout;
