import AccountSection from './components/AccountSection';
import AccountProtestList from './components/AccountProtestList';
import { ServiceService } from 'src/services/api';
import { useAuthStore } from 'src/stores/AuthStore';
import { useEffect } from 'react';
import './style.css';
import { useMount } from 'app-studio';

function AccountPage() {
  const JoinedProtests = ServiceService.useGetService1();
  const VituralProtests = ServiceService.useGetService2();

  const { user } = useAuthStore();

  useMount(() => {
    if (user.email) JoinedProtests.run(user.email);
    if (user.email) VituralProtests.run(user.email);
  });

  //console.log({ JoinedProtests, VituralProtests });
  return (
    <>
      <AccountProtestList data={JoinedProtests.data} />
      <AccountSection />
    </>
  );
}

export default AccountPage;
