import { View } from 'app-studio';
import { Center } from '@app-studio/web';
import RegisterForm from '../../forms/RegisterForm';
import { useNavigate } from 'react-router-dom';
import { VerticalScroll } from 'src/components/Layout';
import i18n from 'src/utils/locale';
import { BackToHomeButton } from 'src/components/Button';

function RegisterPage() {
  const navigate = useNavigate();

  return (
    <LoginContainer>
      <BackToHomeButton />

      <Message>{i18n.t('page.register.joinUs')}</Message>
      <RegisterForm />
      <Center onClick={() => navigate('/auth/login')}>
        <PasswordLabel>{i18n.t('page.register.alreadyAccount')}</PasswordLabel>
        <SignIn>{i18n.t('page.register.signIn')}</SignIn>
      </Center>
      {/* <Right onClick={() => navigate('/security/reset-password')}>
          <PasswordLabel>Forget password</PasswordLabel>
        </Right> */}
    </LoginContainer>
  );
}

export default RegisterPage;

const Message = (props) => (
  <View
    font={'600 32px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    marginTop={15}
    marginBottom={15}
    textAlign="center"
    {...props}
  />
);

const LoginContainer = (props) => (
  <VerticalScroll
    paddingTop={20}
    paddingBottom={60}
    flexDirection={'column'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const PasswordLabel = (props) => (
  <View font={'400 16px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const SignIn = (props) => (
  <View
    font={'500 16px Work Sans'}
    color={'rgba(85, 52, 165, 1)'}
    textDecorationLine={'underline'}
    marginLeft={10}
    {...props}
  />
);
