import React, { Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JoinModal from 'src/pages/JoinModal';
import AccountPage from 'src/pages/AccountPage';
import RegisterPage from 'src/pages/RegisterPage';
import PasswordChangePage from 'src/pages/PasswordChangePage';
import EventPage from 'src/pages/EventPage';
import ResetPassword from 'src/pages/ResetPassword';
import UserConfirmationPage from 'src/pages/UserConfirmationPage';

import { Horizontal, Text, Vertical } from '@app-studio/web';
import HomePage from './HomePage/home.page';

export const componentList = [
  { name: 'Home', element: <HomePage /> },
  { name: 'Register', element: <RegisterPage /> },
  { name: 'ResetPassword', element: <ResetPassword /> },
  { name: 'JoinModal', element: <JoinModal /> },
  { name: 'PasswordChange', element: <PasswordChangePage /> },
  { name: 'UserConfirmation', element: <UserConfirmationPage /> },
  { name: 'Account', element: <AccountPage /> },
  { name: 'Event', element: <EventPage /> },
];

export const ComponentsPage = () => {
  const navigate = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selected, setSelected] = useState(componentList[0]);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  return (
    <Horizontal height="100vh" wrap="nowrap">
      <Vertical
        flex={1}
        boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)"
        transition="box-shadow 0.3s ease-in-out"
      >
        <Text onClick={() => navigate('home')}>Navigation</Text>
        <ul>
          {componentList.map((item, index) => (
            <li
              key={index}
              className={hoveredIndex.toString()}
              onClick={() => setSelected(item)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </Vertical>
      <Vertical flex={4} padding="1rem 2rem" gap={10}>
        <Suspense fallback={<div>Loader...</div>}>{selected.element}</Suspense>
      </Vertical>
    </Horizontal>
  );
};

export default ComponentsPage;
