import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
    
  body {
    margin: 0;
    box-sizing: border-box;
  }

  .truncate-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines you want to display */
    overflow: hidden;
    text-overflow: ellipsis;
  }
    

`;
