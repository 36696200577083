import React, { ReactNode, useState } from 'react';
import { RouterProvider } from 'src/providers/Router';
import { ThemeProvider, ResponsiveProvider, useMount } from 'app-studio';
import { RequestProvider } from '@app-studio/react-request';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
interface WrapperProps {
  children: ReactNode;
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const [online, setOnline] = useState(true);
  const [status, setAppStatus] = useState('active');

  useMount(() => {
    document.addEventListener(
      'visibilitychange',
      () => {
        setAppStatus(document.hidden !== true ? 'active' : 'inactive');
      },
      false
    );

    if (typeof window !== 'undefined') {
      window.addEventListener('online', () =>
        setOnline(window.navigator.onLine)
      );
      window.addEventListener('offline', () =>
        setOnline(window.navigator.onLine)
      );
    }
  });
  return (
    <RequestProvider
      cached={true}
      debug={true}
      connectionStatus={online}
      appStatus={status}
      every={{
        onError: (
          e
          // params, name, method
        ) => {
          console.error(e);
          // error(e);
        },
        onOnline: (run, params, name, method) => {
          if (method === 'GET') {
            run(...params);
          }
        },
        onAppStatusChange: (status, run, params, name, method) => {
          if (method === 'GET' && status === 'active') {
            run(...params);
          }
        },
      }}
    >
      <RouterProvider>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider>
            <ResponsiveProvider>{children}</ResponsiveProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </RouterProvider>
    </RequestProvider>
  );
};

export default Wrapper;
