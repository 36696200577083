import React, { useState, useCallback, useEffect, useRef } from 'react';
import { View, Span, Image, isBrowser } from 'app-studio';
import { useUploadRequest } from '@app-studio/react-request';
import { useAuthStore } from 'src/stores/AuthStore';
import { OpenAPI, ServiceService } from 'src/services/api';
import { Center } from '@app-studio/web';

function ImageUploader() {
  const fileInputRef: any = useRef(null);
  const user = useAuthStore((state) => state.user);
  const updateUser = ServiceService.useOptionsService2({
    onSuccess: () => {
      useAuthStore.getState().refresh();
    },
  });

  const uploadImageRequest = ServiceService.useUploadImageService({
    onSuccess: ({ body }) => {
      console.log('body', body);
      if (user.email)
        updateUser.run(user.email, { tempAvatarID: body.tempAvatarID }); // manualAdBannerReadRequest.run(params.id);
    },
    onError: (error) => {
      if (error.message) alert(error.message);
    },
  });

  const handleImageChange = useCallback(async (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Image = reader.result;

      try {
        console.log('base64Image', base64Image);
        uploadImageRequest.run(base64Image as string);
        // await upload({
        //   file: base64Image,
        //   url: API_URL + '/users/upload-temp-avatar',
        //   onSuccess: (data) => {
        //     console.log('data', data);
        //     //setTempAvatarID(tempID);
        //     // manualAdBannerReadRequest.run(params.id);
        //   },
        //   onFailure: (error) => {
        //     console.log('error', error);
        //   },
        //   onProgress: (progress) => {
        //     console.log('progress', progress);
        //   },
        //   access_token: useAuthStore.getState().token as string,
        // });
        // const tempID = await uploadTempAvatar(base64Image);
      } catch (error) {
        console.error("Erreur lors de l'upload:", error);
      }
    };
    reader.readAsDataURL(file);
  }, []);

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click(); // Déclenche le clic sur l'input file
  };

  // Ajouter ici la logique pour gérer l'enregistrement de l'utilisateur avec tempAvatarID

  return (
    <ImageUploadContainer onClick={handleClick}>
      {uploadImageRequest.loading && uploadImageRequest.loading ? (
        // <Span>({uploadImageRequest.progess}%)</Span>
        <Span>Loading...</Span>
      ) : (
        <Image width={'100%'} src={user.avatar} alt="Uploaded Avatar" />
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
    </ImageUploadContainer>
  );
}

export default ImageUploader;

const ImageUploadContainer = (props) => (
  <Center
    justify={'center'}
    align={'center'}
    boxSizing={'border-box'}
    size={146}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={146}
    overflow="hidden"
    {...props}
  />
);
