import React, { useState, useEffect, useRef } from 'react';
import { View } from 'app-studio';
import PersonCard from '../PersonCard';
import i18n from 'src/utils/locale';
import { request as __request } from '../../../../services/api/core/request';
import { useAuthStore, isAuthentificated } from 'src/stores/AuthStore';
import RefreshAnimation from './json/refresh.json';
import Lottie from 'lottie-react';

function ParticipantList({ id, participating }) {
  const [data, setData] = useState();
  const [lastKey, setLastKey] = useState();
  const scrollContainer = useRef(null);
  const user = useAuthStore((state) => state.user);
  const lottieListRef = useRef();

  // Add a ref to keep track of the last key fetched during the current session
  const lastFetchedKeyRef = useRef(null);

  const fetchData = async () => {
    try {
      // Use the last fetched key if it's available
      const keyToUse = lastFetchedKeyRef.current || lastKey;

      const res = await __request({
        method: 'GET',
        path: `/virtual-protests/${id}/participants`,
        query: {
          lastEvaluatedKey: keyToUse,
          callerEmail: isAuthentificated() && participating ? user.email : null,
        },
      });

      console.log('keyToUse after fetch: ', keyToUse);
      console.log(
        'lastFetchedKeyRef.current after fetch: ',
        lastFetchedKeyRef.current
      );

      if (res.statusCode !== 200) throw new Error(res.body.message);

      const newData = data ? [...data, ...res.body] : [...res.body];
      setData(newData);

      // Update the last key and the last fetched key
      const newLastKey = window.btoa(JSON.stringify(res.lastEvaluatedKey));
      setLastKey(newLastKey);
      lastFetchedKeyRef.current = newLastKey;

      console.log('keyToUse after data set update: ', keyToUse);
      console.log(
        'lastFetchedKeyRef.current after data set update: ',
        lastFetchedKeyRef.current
      );
    } catch (err) {
      console.log(err);
    }
  };

  //init the first lastKey without add the items twice.
  useEffect(() => {
    if (!id) return;
    fetchData();
  }, [id, participating]);

  const handleScroll = () => {
    const { scrollLeft, clientWidth, scrollWidth } = scrollContainer.current;
    if (scrollLeft + clientWidth < scrollWidth - 1) return;
    console.log('Arrivé au bout du scroll horizontal!');
    lottieListRef.current.stop();
    lottieListRef.current.goToAndStop(0, true);
    lottieListRef.current.play();
    fetchData();
  };

  if (!data) return null;

  return (
    <ProtestDetails>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ProtestHeading>{i18n.t('page.event.participants')}</ProtestHeading>
        <Lottie
          onClick={() => {
            lottieListRef.current.stop();
            lottieListRef.current.goToAndStop(0, true);
            lottieListRef.current.play();
            fetchData();
          }}
          style={{ width: 200 }}
          onLoadedImages={() => {}}
          onComplete={() => {}}
          loop={false}
          autoplay={false}
          lottieRef={lottieListRef}
          animationData={RefreshAnimation}
        />
      </div>
      <Participants>
        <div
          ref={scrollContainer}
          onScroll={handleScroll}
          style={{
            width: 'calc(100vw-80px)',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              width: 'fit-content',
            }}
          >
            {data &&
              data.map((item, index) => (
                <Card2 minWidth={300} key={'card' + index}>
                  <Card>
                    <PersonCard item={item} userEmail={user.email} />
                  </Card>
                </Card2>
              ))}
          </div>
        </div>
      </Participants>
    </ProtestDetails>
  );
}

export default ParticipantList;

const ProtestDetails = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={'40px'}
    {...props}
  />
);

const ProtestHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const Participants = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={19}
    alignItems={'center'}
    justifyContent={'space-between'}
    marginTop={20}
    {...props}
  />
);

const ButtonSpacer = (props) => (
  <View flex={'0 0 auto'} paddingTop={121} paddingBottom={121} {...props} />
);

const ButtonContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={48}
    height={48}
    padding={15}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(85, 52, 165, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(16, 24, 40, 0.05)'}
    {...props}
  />
);

const Card2 = (props) => (
  <View
    boxSizing={'border-box'}
    paddingVertical={20}
    media={{ mobile: { paddingVertical: 0 } }}
    {...props}
  />
);

const Card = (props) => (
  <View
    display={'flex'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    paddingRight={20}
    paddingLeft={20}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    opacity={0.8}
    {...props}
  />
);
