// button.tsx
import React from 'react';
import { Button, View } from 'app-studio';

import i18n from 'src/utils/locale';
import { FileSVG, Horizontal, Center } from '@app-studio/web';
import { useNavigate } from 'react-router-dom';

export const BackToHomeButton = (props: any) => {
  const navigate = useNavigate();

  return (
    <Horizontal>
      <BackIconContainer onClick={() => navigate('/')}>
        <FileSVG src="/assets/back.svg" />
      </BackIconContainer>
      <BackLink>{i18n.t('button.backtoHome')}</BackLink>
    </Horizontal>
  );
};

const BackLink = (props) => (
  <Center
    padding={10}
    font={'500 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const BackIconContainer = (props) => (
  <View
    boxSizing={'border-box'}
    alignItems={'center'}
    justifyContent={'center'}
    width={48}
    height={48}
    padding={15}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(16, 24, 40, 0.05)'}
    {...props}
  />
);
