function SvgIcon1({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <g id="signal 1" xmlns="http://www.w3.org/2000/svg">
        <g id="Group_3">
          <path
            id="Vector_8"
            d="M3.496,3.1816c-0.062,0.027 -0.23,0.172 -0.371,0.32c-1.109,1.18 -1.902,2.613 -2.289,4.141c-0.223,0.886 -0.27,1.289 -0.27,2.359c0,1.07 0.047,1.473 0.27,2.359c0.336,1.325 0.988,2.602 1.887,3.684c0.609,0.738 0.816,0.879 1.172,0.812c0.191,-0.035 0.425,-0.269 0.46,-0.46c0.051,-0.262 -0.003,-0.403 -0.281,-0.704c-1.519,-1.648 -2.277,-3.546 -2.277,-5.691c0,-2.152 0.754,-4.031 2.281,-5.699c0.176,-0.188 0.262,-0.317 0.281,-0.41c0.094,-0.512 -0.398,-0.914 -0.863,-0.711z"
            fill="currentColor"
          />
          <path
            id="Vector_9"
            d="M15.996,3.1816c-0.16,0.07 -0.324,0.269 -0.355,0.433c-0.047,0.254 0.011,0.399 0.285,0.696c1.515,1.648 2.277,3.55 2.277,5.691c0,2.141 -0.762,4.043 -2.277,5.691c-0.278,0.301 -0.332,0.442 -0.282,0.704c0.036,0.191 0.27,0.425 0.461,0.461c0.301,0.058 0.411,0.003 0.778,-0.375c1.062,-1.09 1.883,-2.575 2.277,-4.114c0.227,-0.879 0.274,-1.293 0.274,-2.367c0,-1.074 -0.047,-1.488 -0.274,-2.367c-0.394,-1.539 -1.176,-2.953 -2.285,-4.133c-0.344,-0.367 -0.578,-0.453 -0.879,-0.32z"
            fill="currentColor"
          />
          <path
            id="Vector_10"
            d="M5.957,5.5676c-0.324,0.11 -1.07,1.051 -1.422,1.79c-0.207,0.433 -0.371,0.914 -0.48,1.398c-0.078,0.355 -0.09,0.508 -0.09,1.246c0,0.738 0.012,0.891 0.09,1.246c0.109,0.485 0.273,0.965 0.48,1.399c0.36,0.753 1.133,1.718 1.449,1.8c0.399,0.106 0.774,-0.179 0.774,-0.589c0,-0.235 -0.031,-0.297 -0.324,-0.614c-0.125,-0.14 -0.325,-0.394 -0.438,-0.566c-0.555,-0.84 -0.801,-1.66 -0.801,-2.676c0,-1.016 0.246,-1.836 0.801,-2.676c0.113,-0.172 0.313,-0.426 0.438,-0.566c0.293,-0.317 0.324,-0.379 0.324,-0.617c0,-0.418 -0.403,-0.707 -0.801,-0.575z"
            fill="currentColor"
          />
          <path
            id="Vector_11"
            d="M13.723,5.5486c-0.297,0.066 -0.481,0.297 -0.481,0.598c0,0.234 0.031,0.296 0.324,0.613c0.598,0.656 1.024,1.523 1.18,2.398c0.074,0.446 0.074,1.242 0,1.688c-0.156,0.875 -0.582,1.742 -1.18,2.398c-0.293,0.317 -0.324,0.379 -0.324,0.614c0,0.41 0.375,0.695 0.774,0.589c0.316,-0.082 1.089,-1.047 1.449,-1.8c0.207,-0.434 0.371,-0.914 0.48,-1.399c0.078,-0.355 0.09,-0.508 0.09,-1.246c0,-0.738 -0.012,-0.891 -0.09,-1.246c-0.238,-1.066 -0.754,-2.074 -1.433,-2.809c-0.344,-0.371 -0.52,-0.461 -0.789,-0.398z"
            fill="currentColor"
          />
          <path
            id="Vector_12"
            d="M9.512,7.3646c-1.629,0.301 -2.61,2.024 -2.028,3.563c0.434,1.164 1.645,1.906 2.825,1.738c1.273,-0.18 2.175,-1.082 2.355,-2.355c0.16,-1.129 -0.531,-2.325 -1.609,-2.774c-0.508,-0.215 -1.016,-0.269 -1.543,-0.172zM10.281,8.5996c0.301,0.066 0.536,0.195 0.743,0.418c0.222,0.238 0.324,0.429 0.382,0.742c0.086,0.433 -0.047,0.867 -0.375,1.219c-0.539,0.578 -1.402,0.609 -1.992,0.07c-0.375,-0.344 -0.539,-0.82 -0.445,-1.289c0.062,-0.32 0.164,-0.516 0.387,-0.746c0.203,-0.215 0.378,-0.317 0.679,-0.399c0.254,-0.066 0.36,-0.07 0.621,-0.015z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIcon1;
