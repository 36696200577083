import { View, Input, Image } from 'app-studio';
import { Button } from '@app-studio/web';
import { forwardRef, useCallback, useState } from 'react';

import ProtestCard from '../HomeProtestCard';
import { useNavigate } from 'react-router';
import i18n from 'src/utils/locale';

function ProtestParticipants({
  activeParticipantsCount = 0,
  firstFourParticipants = [],
  title = '',
  description = '',
  id,
}) {
  return (
    <ProtestCard1>
      <ProtestCard title={title} description={description} id={id} />
      <ParticipantSection>
        <ParticipantTitle>
          {i18n.t('page.home.currentParticipants')}
        </ParticipantTitle>
        <ParticipantContainer>
          {firstFourParticipants.map((participant: any, index) =>
            index == 0 ? (
              <ProtestInfo key={'protest_' + index}>
                <CircularImageImg src={participant.avatar} />
              </ProtestInfo>
            ) : (
              <CardContainer key={'protest_' + index}>
                <CircularImageImg src={participant.avatar} />
              </CardContainer>
            )
          )}

          {activeParticipantsCount > 4 && (
            <RoundedBox>
              <AgeLabel>{activeParticipantsCount - 4}+</AgeLabel>
            </RoundedBox>
          )}
        </ParticipantContainer>
        {/* <ParticipantLink >
          View all
        </ParticipantLink> */}
      </ParticipantSection>
    </ProtestCard1>
  );
}

export default ProtestParticipants;

const ProtestCard1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    zIndex={100}
    paddingHorizontal={32}
    {...props}
  />
);

const ParticipantSection = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    media={{
      mobile: { flexDirection: 'column', marginTop: 20 },
      tablet: { marginTop: 10 },
    }}
    alignItems={'flex-start'}
    justifyContent={'space-between'}
    marginTop={43}
    {...props}
  />
);

const ParticipantTitle = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 16px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    marginVertical={5}
    {...props}
  />
);

const ParticipantContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    height={40}
    {...props}
  />
);

const ProtestInfo = (props) => <View flex={'0 0 auto'} {...props} />;

const CircularImageImg = ({ src }) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={40}
    maxWidth={'initial'}
    height={40}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    src={src}
  />
);

const CardContainer = (props) => (
  <View flex={'0 0 auto'} marginLeft={'-8px'} {...props} />
);

const RoundedBox = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingRight={8}
    paddingLeft={8}
    marginLeft={'-8px'}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(85, 52, 165, 1)'}
    borderRadius={'50%'}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);

const AgeLabel = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 16px Work Sans'}
    color={'rgba(85, 52, 165, 1)'}
    {...props}
  />
);

const ParticipantLink = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 16px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    textDecorationLine={'underline'}
    marginLeft={30}
    {...props}
  />
);
