import { View, Image } from 'app-studio';
import { Button } from '@app-studio/web';
import 'react';
import SvgIcon1 from './icons/SvgIcon1';
import SvgIcon2 from './icons/SvgIcon2';
import SvgIcon3 from './icons/SvgIcon3';
import { useNavigate } from 'react-router';
import { useAuthStore, isAuthentificated } from 'src/stores/AuthStore';

function TopBar() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  return (
    <ProtestContainer>
      <ProtestContainer1
        onClick={() => {
          navigate('/');
        }}
      >
        <VirtualProtestContainer>
          <ImageContainerImgContentE9deb7c1>
            <SvgIcon1 />
          </ImageContainerImgContentE9deb7c1>
        </VirtualProtestContainer>
        <VirtualProtestTitle>Virtual protest</VirtualProtestTitle>
      </ProtestContainer1>
      <ProtestContainer1>
        <RoundedContainer
          onClick={() => {
            if (isAuthentificated()) navigate('/account');
            else navigate('/auth/login');
          }}
          padding={isAuthentificated() && user ? 0 : 10}
        >
          {isAuthentificated() && user ? (
            <Image src={user.avatar} width="100%" />
          ) : (
            <SvgIcon2 />
          )}
        </RoundedContainer>
        <SvgIcon3 />
      </ProtestContainer1>
    </ProtestContainer>
  );
}

export default TopBar;

const ProtestContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flexDirection={'row'}
    gap={8}
    alignItems={'center'}
    justifyContent={'space-between'}
    height={80}
    paddingHorizontal={65}
    media={{
      mobile: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    }}
    width="100%"
    background={'rgba(255, 255, 255, 1)'}
    borderBottom={'1px solid #ededee'}
    {...props}
  />
);

const ProtestContainer1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const VirtualProtestContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const ImageContainerImgContentE9deb7c1 = (props) => (
  <View
    boxSizing={'border-box'}
    display={'block'}
    flex={'0 0 auto'}
    alignSelf={'stretch'}
    width={33}
    maxWidth={'initial'}
    height={41}
    color="white"
    paddingTop={15}
    paddingHorizontal={7}
    backgroundImage={'url("/assets/img-content-e9deb7c1.svg")'}
    {...props}
  />
);

const VirtualProtestTitle = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={7.25}
    font={'600 22px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const RoundedContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={50}
    height={50}
    overflow="hidden"
    color="grey"
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={'50%'}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);
