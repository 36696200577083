import { View, Input } from 'app-studio';
import { Button } from '@app-studio/web';
import { forwardRef, useCallback, useState } from 'react';

function VAccountCreationForm() {
  return (
    <PasswordResetContainer1>
      {/* Input with Label Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <EmailContainer>
        <EmailLabel>E-mail address</EmailLabel>
        <EmailInputInputStyleF62Placeholder
          placeholder="Enter your e-mail address"
          type="text"
        />
      </EmailContainer>
      {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <CreateAccountButton>Create my account</CreateAccountButton>
    </PasswordResetContainer1>
  );
}

export default VAccountCreationForm;

const PasswordResetContainer1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const EmailContainer = (props) => (
  <View
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'start'}
    height={81}
    border={'none'}
    {...props}
  />
);

const EmailLabel = (props) => (
  <View
    display={'block'}
    marginBottom={10}
    font={'400 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const EmailInputInputStyleF62Placeholder = (props) => (
  <Input
    boxSizing={'border-box'}
    width={'100%'}
    height={48}
    paddingLeft={16}
    font={'400 16px Work Sans'}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={6}
    color={'rgba(150, 145, 161, 1)'}
    {...props}
  />
);

const CreateAccountButton = (props) => (
  <Button
    boxSizing={'border-box'}
    display={'block'}
    flex={'0 0 auto'}
    width={504}
    height={48}
    marginTop={40}
    font={'500 18px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    cursor={'pointer'}
    background={'linear-gradient(90.44deg, #7a48f3 131.89%, #391989 7.68%)'}
    border={'none'}
    borderRadius={8}
    {...props}
  />
);
