import { useCallback, useState, forwardRef } from 'react';
import SvgIcon1 from './icons/SvgIcon1';
import SvgIcon2 from './icons/SvgIcon2';
import './style.css';

function PasswordChangeForm() {
  return (
    <div className={`password-reset-form`}>
      {/* Input with Label Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <div className={`password-input-container`}>
        <label className={`password-label`}>New Password</label>
        <div className={`password-container`}>
          <input
            placeholder="Enter your new password"
            type="text"
            className={`input-container input-style-f62::placeholder`}
          />
          <SvgIcon1 className="svg-container" />
        </div>
      </div>

      {/* Input with Label Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <div className={`password-input-container`}>
        <label className={`password-label`}>Re-enter password</label>
        <div className={`password-container`}>
          <input
            placeholder="Re - enter your new password"
            type="text"
            className={`input-container input-style-f62::placeholder`}
          />
          <SvgIcon2 className="svg-container" />
        </div>
      </div>

      {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <button className={`sign-in-button`}>Sign in</button>
    </div>
  );
}

export default PasswordChangeForm;
