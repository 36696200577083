import { View, Image } from 'app-studio';
import { Button, Text } from '@app-studio/web';
import i18n from 'src/utils/locale';
import { useNavigate } from 'react-router-dom';

function ProtestCard({ title = '', description = '', id }) {
  const navigate = useNavigate();

  return (
    <ProtestInfo>
      <ProtestName>{title}</ProtestName>
      {id && (
        <Button
          backgroundColor="white"
          color="#5534A5"
          width={160}
          marginVertical={10}
          media={{
            mobile: { display: 'block', width: '90%', marginTop: 20 },
            tablet: { display: 'none' },
            desktop: { display: 'none' },
          }}
          onClick={() => navigate('/event/' + id)}
        >
          {i18n.t('page.home.join')}
        </Button>
      )}
      <ProtestDescription
        media={{
          mobile: { display: 'none' },
        }}
      >
        {description}
      </ProtestDescription>
    </ProtestInfo>
  );
}

export default ProtestCard;
const ProtestInfo = (props) => <View flex={'0 0 auto'} {...props} />;
const ProtestName = (props) => (
  <View
    font={'600 24px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    {...props}
  />
);

const ProtestDescription = (props) => (
  <Text
    marginTop={8}
    font={'400 16px Work Sans'}
    color={'rgba(210, 200, 234, 1)'}
    maxLines={3}
    isTruncated={true}
    lineHeight={20}
    {...props}
  />
);
