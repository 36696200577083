import React from 'react';
import { Button, Modal, hideModal } from '@app-studio/web';

export const AlertModal = ({
  title = '',
  message = '',
  action = 'OK',
  onClick,
}) => {
  return (
    <Modal.Container>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (onClick) {
              onClick();
            }
            hideModal();
          }}
        >
          {action}
        </Button>
      </Modal.Footer>
    </Modal.Container>
  );
};
