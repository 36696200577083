import { View, Input } from 'app-studio';
import { useCallback, useState, forwardRef } from 'react';

function WriteMessage() {
  return (
    <SloganContainer>
      <WorldMessageSlogan>
        Please write your slogan/message to the world
      </WorldMessageSlogan>
      {/* Input Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
      <SloganInputInputStyleF62Placeholder
        placeholder="Enter your slogan"
        type="text"
      />
    </SloganContainer>
  );
}

export default WriteMessage;

const SloganContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const WorldMessageSlogan = (props) => (
  <View
    flex={'0 0 auto'}
    font={'400 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const SloganInputInputStyleF62Placeholder = (props) => (
  <Input
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    height={48}
    paddingLeft={16}
    marginTop={10}
    font={'400 16px Work Sans'}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={6}
    color={'rgba(150, 145, 161, 1)'}
    {...props}
  />
);
