import moment from 'src/utils/moment';

export function getStatus(event: any) {
  const now = moment(); // Définissez la variable now pour la date et l'heure actuelles

  const startDate = moment(event.startDate);
  const endDate = startDate.clone().add(event.duration, 'hours'); // Cloner pour ne pas modifier startDate
  //  return 'ongoing';
  if (startDate.isSameOrBefore(now) && endDate.isAfter(now)) return 'ongoing';
  if (startDate.isAfter(now)) return 'upcoming';
  if (endDate.isBefore(now)) return 'ended';

  return 'unknown';
}
