import { View, Image } from 'app-studio';
import { Button, Left, Right, Vertical } from '@app-studio/web';
import { useNavigate } from 'react-router';
import i18n from 'src/utils/locale';

function JoinSection({ data }) {
  const navigate = useNavigate();

  return (
    <JoinNowContainer media={{ mobile: { display: 'none' } }}>
      <Button
        backgroundColor="white"
        color="#5534A5"
        width={160}
        onClick={() => {
          if (data?.body?.protestID) navigate('/event/' + data.body.protestID);
        }}
      >
        {i18n.t('page.home.join')}
      </Button>
      <JoinButtonContainer>
        <ImageContainerImgContentCd5c4a0d />
      </JoinButtonContainer>
    </JoinNowContainer>
  );
}

export default JoinSection;

const JoinNowContainer = (props) => (
  <Right>
    <Vertical
      boxSizing={'border-box'}
      alignSelf={'center'}
      justifyContent={'center'}
      paddingVertical={50}
      paddingHorizontal={'10vw'}
      {...props}
    />
  </Right>
);

const JoinButtonContainer = (props) => (
  <View flex={'0 0 auto'} marginTop={58} {...props} />
);

const ImageContainerImgContentCd5c4a0d = (props) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={140}
    maxWidth={'initial'}
    src="/assets/img-content-cd5c4a0d.svg"
    {...props}
  />
);
