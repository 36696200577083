import { View, Image } from 'app-studio';
import { useCallback, useState, forwardRef } from 'react';
import AccountCreationForm from '../VAccountCreationForm';
import SvgIcon1 from './icons/SvgIcon1';

function AccountPage() {
  return (
    <AccountCreationSection>
      <AccountCreationForm />
      <BackButtonContainer>
        <SvgIcon1 />
        <SignInLink>Go back to sign in</SignInLink>
      </BackButtonContainer>
    </AccountCreationSection>
  );
}

export default AccountPage;

const AccountCreationSection = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={40}
    {...props}
  />
);

const BackButtonContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    marginTop={15.5}
    {...props}
  />
);

const SignInLink = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={7}
    font={'500 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);
