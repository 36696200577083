import { View, Image, useMount } from 'app-studio';

import LiveStreamPage from '../HomeHero';
import JoinSection from '../HomeJoinSection';
import { ServiceService } from 'src/services/api';

export const HomeLive = () => {
  const MainProtest = ServiceService.useGetService4();

  useMount(() => {
    MainProtest.run();
  });

  if (!MainProtest.data) return null;

  return (
    <HomeHero>
      <LiveStreamPage data={MainProtest.data} />
      {MainProtest.data && <JoinSection data={MainProtest.data} />}
    </HomeHero>
  );
};

export default HomeLive;

const HomeHero = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'flex-start'}
    justifyContent={'flex-start'}
    background={'rgba(85, 52, 165, 1)'}
    borderRadius={8}
    overflow="hidden"
    width={'calc(100% - 20px)'}
    height={400}
    {...props}
  />
);
