import { View } from 'app-studio';
import { useEffect, useState, useRef, MouseEvent } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';
import Lottie from 'lottie-react';
import ShareAnimation from './json/share.json';
import { I18n } from 'i18n-js';
import en from 'src/locales/en';
import { fr } from 'date-fns/locale';

export const i18n = new I18n({
  en,
  fr,
});

interface ShareButtonProps {
  url: string;
  title: string;
}

export default function ShareButton({ url, title }: ShareButtonProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!buttonRef.current) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (!buttonRef.current?.contains(event.target as Node)) setOpen(false);
    };

    document.addEventListener(
      'mousedown',
      handleClickOutside as unknown as EventListener
    );

    return () => {
      // Clean up the event listener
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener
      );
    };
  }, [buttonRef.current]);

  const properties = {
    style: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 5,
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <ShareButtonStyle>
        <div ref={buttonRef}>
          <Lottie
            style={{ padding: 0, margin: 0 }}
            onLoadedImages={() => {}}
            onComplete={() => {}}
            loop={true}
            autoplay={true}
            animationData={ShareAnimation}
            onClick={(e) => {
              setOpen((prev) => !prev);
            }}
          />

          {open && (
            <ShareList>
              <FacebookShareButton url={url} hashtag={title} {...properties}>
                <FacebookIcon size={32} round={true} />
                Facebook
              </FacebookShareButton>
              <TwitterShareButton url={url} title={title} {...properties}>
                <TwitterIcon size={32} round={true} />
                Twitter
              </TwitterShareButton>
            </ShareList>
          )}
        </div>
      </ShareButtonStyle>
    </div>
  );
}

const ShareButtonStyle = (props) => (
  <View
    display="flex"
    justifyContent="flex-start"
    alignItems="flex-end"
    width={'200px'}
    position={'relative'}
    {...props}
  />
);

const ShareList = (props) => (
  <View
    padding={10}
    borderRadius={4}
    backgroundColor={'color.white'}
    display={'flex'}
    flexDirection={'column'}
    gap={10}
    color={'black'}
    width={'fit-content'}
    position={'absolute'}
    heigth={'50px'}
    top={'40px'}
    left="190px"
    boxShadow={'rgba(0, 0, 0, 0.35) 0px 5px 15px'}
    zIndex={10}
    {...props}
  />
);
