function SvgIcon3({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
    >
      <g id="chevron-down_2" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector_26"
          d="M15.83,7.08335l-5.83,5.8333l-5.83,-5.8333"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default SvgIcon3;
