import { useEffect } from 'react';
import i18n from 'src/utils/locale';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const GoogleRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      const exchangeCodeForToken = async () => {
        //Call the backend API with URL https://ja8d5c7gw5.execute-api.eu-west-1.amazonaws.com/dev/users/oauth2 with the code in que query parameter to exchange the code for a token
        //The backend API will call the Google API with the code to exchange it for a token
        //The backend API will return the token
        //The frontend will store the token in the local storage
        //The frontend will redirect the user to the home page

        // const response = await axios.post(
        //   `${process.env.REACT_APP_API_URL}/users/oauth2`,
        //   {
        //     code,
        //   }
        // );
        // const { token } = response.data;
        // localStorage.setItem('token', token);
        // navigate('/');

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/oauth2`,
          {
            code,
          }
        );
        const { token } = response.data;
      };

      exchangeCodeForToken();
    }
  }, [location, navigate]);

  return null; // or a loading spinner, or whatever you want to display while the code is being exchanged for a token
};

export default GoogleRedirect;
