import { View, Image, Span } from 'app-studio';
import {
  Button,
  FileSVG,
  Horizontal,
  Left,
  Right,
  Vertical,
} from '@app-studio/web';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

function HomeCategory({ menu, selected, onChange }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPagesize] = useState(parseInt(window.innerWidth / 150));

  let maxPage = parseInt(menu.length / pageSize) - 1;

  window.addEventListener('resize', () => {
    const newSize = parseInt(window.innerWidth / 150);

    if (newSize != pageSize) {
      setPagesize(parseInt(window.innerWidth / 150));
      maxPage = parseInt(menu.length / pageSize) - 1;
    }
  });

  return (
    <ContainerBox1
      media={{
        mobile: { overflow: 'hidden' },
      }}
    >
      <EventStatusCounts>
        <CategoryContainer>
          {/* <Left>
            <Button
              marginBottom={10}
              backgroundColor="white"
              color={page > 0 ? '#5534A5' : 'white'}
              width={30}
              paddingLeft={10}
              paddingRight={10}
              media={{
                mobile: { display: 'block' },
                tablet: { display: 'none' },
                desktop: { display: 'none' },
              }}
              isDisabled={page == 0}
              onClick={() => {
                if (page > 0) setPage(page - 1);
              }}
            >
              <FileSVG src={'/assets/chevron-left.svg'} width={8} />
            </Button>
          </Left> */}

          {menu
            // .slice(pageSize * page, pageSize * page + pageSize)
            .map((item) => (
              <Vertical onClick={() => onChange(item)} key={item.label}>
                <ProtestContainer1>
                  <StatusLabel>{item.label}</StatusLabel>
                  <HighlightedCountBox
                    color={item.color}
                    background={item.backgroundColor}
                  >
                    {item.data.length}
                  </HighlightedCountBox>
                </ProtestContainer1>

                {selected.key != item.key && <SectionDivider />}
                {selected.key == item.key && <SectionSelectedDivider />}
              </Vertical>
            ))}
          {/* <Right>
            <Button
              marginBottom={10}
              backgroundColor="white"
              color="#5534A5"
              media={{
                mobile: { display: 'block' },
                tablet: { display: 'none' },
                desktop: { display: 'none' },
              }}
              width={30}
              paddingLeft={10}
              paddingRight={10}
              isDisabled={page == maxPage}
              onClick={() => {
                if (page < maxPage) setPage(page + 1);
              }}
              paddingHorizontal={4}
            >
              <FileSVG
                color={page < maxPage ? '#5534A5' : 'white'}
                src={'/assets/chevron-right.svg'}
                width={8}
              />
            </Button>
          </Right> */}
        </CategoryContainer>
      </EventStatusCounts>
    </ContainerBox1>
  );
}

export default HomeCategory;

const ContainerBox1 = (props) => (
  <View
    flex={'0 0 auto'}
    paddingTop={16}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);

const EventStatusCounts = (props) => (
  <View
    display={'flex'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const CategoryContainer = (props) => (
  <Horizontal
    gap={16}
    paddingRight={4}
    paddingLeft={4}
    media={{ mobile: { width: '100%', gap: 5, flexWrap: 'nowrap' } }}
    alignItems="baseline"
    {...props}
  />
);

const ProtestContainer1 = (props) => (
  <View
    display={'flex'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    paddingLeft={10}
    paddingRight={5}
    media={{
      mobile: { paddingRight: 2, paddingLeft: 2 },
    }}
    {...props}
  />
);

const HighlightedCountBox = (props) => (
  <Span
    boxSizing={'border-box'}
    display={'inline-flex'}
    alignItems={'center'}
    justifyContent={'center'}
    height={20}
    paddingRight={5}
    paddingLeft={5}
    font={'500 14px Work Sans'}
    media={{
      mobile: { fontSize: 10, paddingRight: 2, paddingLeft: 2 },
    }}
    border={'none'}
    borderRadius={23}
    {...props}
  />
);

const StatusLabel = (props) => (
  <View
    font={'400 16px Work Sans'}
    marginRight={5}
    media={{
      mobile: { width: '100%', gap: 5, flexWrap: 'nowrap', fontSize: 12 },
    }}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const SectionSelectedDivider = (props) => (
  <View
    boxSizing={'border-box'}
    width={'100%'}
    height={6}
    marginTop={11}
    background={'rgba(85, 52, 165, 1)'}
    borderRadius={'2px 2px 0px 0px'}
    {...props}
  />
);
const SectionDivider = (props) => (
  <View
    boxSizing={'border-box'}
    height={6}
    marginTop={11}
    borderRadius={'2px 2px 0px 0px'}
    {...props}
  />
);
