import { View, Image, Span } from 'app-studio';
import { useCallback, useState, forwardRef } from 'react';
import { EventDate } from 'src/components/status';
import i18n from 'src/utils/locale';

function ProtestDetail({ data }) {
  return (
    <ProtestDetails>
      <ProtestHeading>{i18n.t('page.event.details')}:</ProtestHeading>
      <ClimateJusticeMarchContainer>
        <EventDate event={data.body} />
        {/* <ClimateJusticeMarchTitle>
          Climate justice march
        </ClimateJusticeMarchTitle> */}
        <ClimateJusticeMarchDescription>
          {data.body.description}
        </ClimateJusticeMarchDescription>
      </ClimateJusticeMarchContainer>
    </ProtestDetails>
  );
}

export default ProtestDetail;

const ProtestDetails = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginVertical={40}
    width={'100%'}
    {...props}
  />
);

const ProtestHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const ClimateJusticeMarchContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    padding={20}
    marginTop={20}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);

const ParticipantSection1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const LiveNowText1 = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={6}
    font={'500 16px Work Sans'}
    color={'rgba(234, 67, 53, 1)'}
    {...props}
  />
);

const ClimateJusticeMarchTitle = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={13}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const ClimateJusticeMarchDescription = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={16}
    font={'400 16px/144% Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    textAlign={'left'}
    {...props}
  />
);
