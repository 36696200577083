import { View, Image } from 'app-studio';
import { Button, Horizontal, Text, Vertical } from '@app-studio/web';
import SvgIconLive from './icons/Live';
import moment from 'src/utils/moment';

import SvgIcon1 from './icons/SvgIcon1';
import SvgIcon2 from './icons/SvgIcon2';
import SignalIcon from './icons/signal';
import { getStatus } from 'src/utils/event';
import i18n from 'src/utils/locale';

export function EventStatusTag({ event }) {
  const status = getStatus(event);
  return (
    <>
      {status == 'upcoming' && (
        <UpcomingContainer>
          <UpcomingText>Upcoming</UpcomingText>
        </UpcomingContainer>
      )}
      {status == 'ended' && (
        <StatusBox>
          <EndedText>Ended</EndedText>
        </StatusBox>
      )}

      {status == 'ongoing' && (
        <OngoingContainer>
          <OngoingStatus1>Ongoing</OngoingStatus1>
        </OngoingContainer>
      )}
    </>
  );
}

export function EventDate({ event, showRemainTime = false }) {
  const status = getStatus(event);
  return (
    <Horizontal>
      {['upcoming'].includes(status) && (
        <EventDateTimeContainer>
          <IconContainer>
            <SvgIcon1 />
          </IconContainer>
          <EventInfo>{moment(event.startDate).format('D MMM YYYY')}</EventInfo>
          <IconContainer>
            <SvgIcon2 />
          </IconContainer>
          <EventInfo>{moment(event.startDate).format('hh:mm A')}</EventInfo>
        </EventDateTimeContainer>
      )}
      {status == 'ongoing' && (
        <Horizontal marginTop={'8px'}>
          <LiveIconContainer>
            <SvgIconLive />
          </LiveIconContainer>

          <LiveNowText>Live now</LiveNowText>
          {showRemainTime && event.startDate && event.duration && (
            <IconContainer>
              <SvgIcon2 />
            </IconContainer>
          )}
          {showRemainTime && event.startDate && event.duration && (
            <EventInfo>
              {i18n.t('page.event.ending')}{' '}
              {moment(event.startDate).add(event.duration, 'hours').fromNow()}
            </EventInfo>
          )}
        </Horizontal>
      )}

      {status == 'ended' && (
        <Horizontal marginTop={'8px'}>
          <SignalIconContainer>
            <SignalIcon />
          </SignalIconContainer>
          <LiveNowText>Ended</LiveNowText>
          <IconContainer>
            <SvgIcon1 />
          </IconContainer>
          <EventInfo>{moment(event.startDate).format('D MMM YYYY')}</EventInfo>
        </Horizontal>
      )}
    </Horizontal>
  );
}

const SignalIconContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    width={15}
    {...props}
  />
);

const LiveIconContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    width={15}
    justifyContent={'flex-start'}
    color="color.red"
    {...props}
  />
);

const IconContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    width={15}
    marginHorizontal={5}
    justifyContent={'flex-start'}
    color="color.gray.500"
    {...props}
  />
);

const LiveNowText = (props) => (
  <View
    flex={'0 0 auto'}
    marginHorizontal={6}
    font={'400 14px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    lineHeight={30}
    {...props}
  />
);

const OngoingContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingRight={6}
    paddingLeft={6}
    marginLeft={8}
    background={'rgba(250, 178, 67, 0.2)'}
    borderRadius={2}
    {...props}
  />
);

const OngoingStatus1 = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 14px Work Sans'}
    color={'rgba(250, 178, 67, 1)'}
    lineHeight={30}
    {...props}
  />
);

const UpcomingContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingRight={6}
    paddingLeft={6}
    marginLeft={8}
    background={'rgba(45, 220, 163, 0.2)'}
    borderRadius={2}
    {...props}
  />
);

const StatusBox = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingRight={6}
    paddingLeft={6}
    marginLeft={8}
    background={'rgba(150, 145, 161, 0.2)'}
    borderRadius={2}
    {...props}
  />
);

const EndedText = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 14px Work Sans'}
    color={'rgba(150, 145, 161, 1)'}
    lineHeight={30}
    {...props}
  />
);

const UpcomingText = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 14px Work Sans'}
    color={'rgba(45, 220, 163, 1)'}
    lineHeight={30}
    {...props}
  />
);

const EventDateTimeContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={5.5}
    alignItems={'center'}
    marginTop={'8px'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const EventInfo = (props) => (
  <View
    font={'400 14px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    marginRight={10}
    lineHeight={30}
    {...props}
  />
);
