function SvgIcon1({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95703 5.56644C5.63281 5.67581 4.88672 6.61722 4.53516 7.3555C4.32812 7.7891 4.16406 8.26956 4.05469 8.75394C3.97656 9.10941 3.96484 9.26175 3.96484 10C3.96484 10.7383 3.97656 10.8907 4.05469 11.2461C4.16406 11.7305 4.32812 12.211 4.53516 12.6446C4.89453 13.3985 5.66797 14.3633 5.98438 14.4453C6.38281 14.5508 6.75781 14.2657 6.75781 13.8555C6.75781 13.6211 6.72656 13.5586 6.43359 13.2422C6.30859 13.1016 6.10938 12.8477 5.99609 12.6758C5.44141 11.836 5.19531 11.0157 5.19531 10C5.19531 8.98441 5.44141 8.1641 5.99609 7.32425C6.10938 7.15238 6.30859 6.89847 6.43359 6.75785C6.72656 6.44144 6.75781 6.37894 6.75781 6.14066C6.75781 5.72269 6.35547 5.43363 5.95703 5.56644Z"
        fill="#837C92"
      />
      <path
        d="M13.7227 5.54691C13.4258 5.61332 13.2422 5.84378 13.2422 6.14457C13.2422 6.37894 13.2734 6.44144 13.5664 6.75785C14.1641 7.4141 14.5898 8.28128 14.7461 9.15628C14.8203 9.6016 14.8203 10.3985 14.7461 10.8438C14.5898 11.7188 14.1641 12.586 13.5664 13.2422C13.2734 13.5586 13.2422 13.6211 13.2422 13.8555C13.2422 14.2657 13.6172 14.5508 14.0156 14.4453C14.332 14.3633 15.1055 13.3985 15.4648 12.6446C15.6719 12.211 15.8359 11.7305 15.9453 11.2461C16.0234 10.8907 16.0352 10.7383 16.0352 10C16.0352 9.26175 16.0234 9.10941 15.9453 8.75394C15.707 7.68753 15.1914 6.67972 14.5117 5.94535C14.168 5.57425 13.9922 5.48441 13.7227 5.54691Z"
        fill="#837C92"
      />
      <path
        d="M8.72498 3.12171C4.47918 3.90571 1.92356 8.39587 3.44064 12.4075C4.57082 15.4416 7.72716 17.3762 10.8021 16.9384C14.1213 16.47 16.4733 14.118 16.9417 10.7988C17.3591 7.85623 15.5569 4.74061 12.7468 3.56971C11.4231 3.00972 10.0995 2.86717 8.72498 3.12171ZM10.7308 6.33915C11.5148 6.51224 12.1257 6.84824 12.6653 7.4286C13.2457 8.04969 13.5104 8.54859 13.6631 9.36313C13.8871 10.4933 13.5409 11.6235 12.6857 12.5398C11.2806 14.0467 9.03043 14.1282 7.49298 12.7231C6.51553 11.8271 6.0879 10.5849 6.33226 9.36313C6.49517 8.52823 6.7599 8.01914 7.34026 7.41842C7.86971 6.85842 8.32789 6.59369 9.11188 6.37988C9.7737 6.20679 10.0486 6.19661 10.7308 6.33915Z"
        fill="#837C92"
      />
    </svg>
  );
}

export default SvgIcon1;
