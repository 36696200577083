import { View, Image } from 'app-studio';
import { useRef, useState } from 'react';
import MapGL, { Source, Layer, Marker, NavigationControl } from 'react-map-gl';

//probleme with transpiler when npm run build
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import * as React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import PinIcon from './icons/pin.png';
import FullscreenAnimation from './json/fullscreen.json';
import Lottie from 'lottie-react';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export default function Map({ items }) {
  const mapRef = useRef();
  const [markerId, setmarkerId] = useState(null);
  const [source, setSource] = useState(null);
  const [mapLoad, setMapLoad] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const lottieListRef = useRef();
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  //make sure to have to correct height for mobile
  React.useEffect(() => {
    const handleResize = () => {
      console.log('hello', window.innerHeight);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    // clean
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (!mapRef.current || !mapLoad) return;
    mapRef.current.resize();
  }, [fullScreen]);

  //get the participant marker info when click on it
  const onClick = (event) => {
    const map = mapRef.current.getMap();
    const features = map.queryRenderedFeatures(event.point, {
      layers: ['unclustered-point'],
    });

    const feature = features[0];
    if (!feature) return setmarkerId(null);

    setmarkerId(feature.properties.id);

    mapRef?.current?.flyTo({
      center: feature.geometry.coordinates,
      duration: 1500,
    });
  };

  //cursor pointer when hover a participant
  React.useEffect(() => {
    if (!mapRef.current || !mapLoad) return;
    mapRef.current.on('mouseenter', 'unclustered-point', () => {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    });
    mapRef.current.on('mouseleave', 'unclustered-point', () => {
      mapRef.current.getCanvas().style.cursor = '';
    });
  }, [mapRef.current, mapLoad]);

  //get live participant list
  React.useEffect(() => {
    setSource({
      type: 'FeatureCollection',
      features: items
        .filter((e) => e.coordinates)
        .map((e) => {
          return {
            type: 'Feature',
            properties: {
              id: e.participantID,
            },
            geometry: {
              type: 'Point',
              coordinates: [e.coordinates.lon, e.coordinates.lat],
            },
          };
        }),
    });
  }, [items]);

  React.useEffect(() => {
    const imageName = 'pin';
    const imageUrl = PinIcon;
    if (!mapRef.current || mapRef.current.hasImage(imageName)) return;

    try {
      mapRef.current.loadImage(imageUrl, (error, image) => {
        if (error) throw error;
        mapRef.current.addImage(imageName, image);
      });
    } catch (error) {
      console.log(error);
    }
  }, [mapRef.current]);

  React.useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.resize();
  }, [fullScreen]);

  React.useEffect(() => {
    if (!mapRef.current || !mapLoad) return;
    const map = mapRef.current.getMap();
    const clusters = map.queryRenderedFeatures({ layers: ['cluster-count'] });

    // Trouver le cluster le plus gros
    let largestCluster = null;
    let maxPointCount = 0;

    clusters.forEach((cluster) => {
      if (cluster.properties.point_count > maxPointCount) {
        largestCluster = cluster;
        maxPointCount = cluster.properties.point_count;
      }
    });

    if (!largestCluster) return;

    //zoomer sur le plus gros cluster
    const coordinates = largestCluster.geometry.coordinates;
    mapRef.current?.flyTo({
      center: coordinates,
      duration: 1500,
      zoom: 3,
    });
  }, [mapLoad, mapRef.current]);

  if (!items) return null;

  return (
    <>
      {source && (
        <MapContainer fullScreen={fullScreen} screenHeight={screenHeight}>
          <ScreenSizeButton onClick={() => setFullScreen((prev) => !prev)}>
            <Lottie
              style={{ width: '50%' }}
              onLoadedImages={() => {}}
              onComplete={() => {}}
              loop={true}
              autoplay={true}
              lottieRef={lottieListRef}
              animationData={FullscreenAnimation}
            />
          </ScreenSizeButton>
          <MapGL
            initialViewState={{
              projection: 'mercator',
              zoom: 0.1,
            }}
            mapStyle="mapbox://styles/mapbox/dark-v11"
            mapboxAccessToken={MAPBOX_TOKEN}
            interactiveLayerIds={[clusterLayer.id]}
            onClick={onClick}
            ref={mapRef}
            onLoad={() => setMapLoad(true)}
            attributionControl={false}
            logo={false}
          >
            <NavigationControl position="bottom-right" showZoom={false} />
            <Source
              id="participants"
              type="geojson"
              data={source}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...clusterLayer} />
              <Layer {...clusterCountLayer} />
              <Layer {...unclusteredPointLayer} />
            </Source>
            {items.map((item, i) => {
              if (markerId !== item.participantID || !item.coordinates) return;
              return (
                <Marker
                  latitude={item.coordinates.lat}
                  longitude={item.coordinates.lon}
                  key={`marker-${i}`}
                >
                  <MarkerInfo>
                    <Profil>
                      <CircularImageImg src={item.avatar} />
                      <NameTitle>{item.name}</NameTitle>
                    </Profil>
                    <SloganText>{item.slogan}</SloganText>
                  </MarkerInfo>
                </Marker>
              );
            })}
          </MapGL>
        </MapContainer>
      )}
    </>
  );
}

const MapContainer = (props) => (
  <View
    overflow="auto"
    width={props.fullScreen ? '100vw' : '100%'}
    height={props.fullScreen ? props.screenHeight : '100%'}
    borderRadius={props.fullScreen ? '' : '25px'}
    position={props.fullScreen ? 'absolute' : 'relative'}
    top={0}
    left={0}
    zIndex={99}
    boxShadow={'0px 3px 6px rgba(16, 24, 40, 0.5)'}
    {...props}
  />
);

const ScreenSizeButton = (props) => (
  <View
    borderRadius={8}
    backgroundColor="#543994"
    position="absolute"
    zIndex={10}
    top={'8px'}
    right={'8px'}
    boxSizing={'border-box'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    width={45}
    height={25}
    padding={4}
    gap={4}
    border={'1px solid rgba(85, 52, 165, 1)'}
    {...props}
  />
);

const MarkerInfo = (props) => (
  <View
    backgroundColor={'white'}
    borderRadius={'10px'}
    padding={'20px'}
    marginTop={'5px'}
    display={'flex'}
    maxWidth={'250px'}
    flexDirection="column"
    {...props}
  />
);

const Profil = (props) => (
  <View marginTop={'5px'} display={'flex'} gap={10} {...props} />
);

const NameTitle = (props) => (
  <View font={'500 20px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const SloganText = (props) => (
  <View
    marginTop={2}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);

const CircularImageImg = ({ src }) => (
  <Image
    boxSizing={'border-box'}
    display={'block'}
    width={32}
    maxWidth={'initial'}
    height={32}
    border={'none'}
    borderRadius={'50%'}
    objectFit={'cover'}
    src={src}
  />
);

const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'participants',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#876cc7',
      100,
      '#543994',
      750,
      '#34245c',
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'participants',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#ffffff',
  },
};

const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'symbol',
  source: 'participants',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'pin',
    'icon-size': 0.3,
  },
};
