import React from 'react';

import AppProvider from 'src/providers';
import ReactDOM from 'react-dom/client';
import AppLayout from 'src/layouts/AppLayout';

import { App } from './Router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <AppLayout>
        <App />
      </AppLayout>
    </AppProvider>
  </React.StrictMode>
);

export default AppLayout;
