import React, { useState } from 'react';
import { View } from 'app-studio';

import moment from 'src/utils/moment';
import EventCard from '../../../../components/EventCard';
import HomeCategory from '../HomeCategory';

import i18n from 'src/utils/locale';

function HomeList({ data }) {
  if (!data) return null;

  const now = moment(); // Définissez la variable now pour la date et l'heure actuelles

  const Menu = [
    {
      key: 'all',
      value: {}, // Ceci doit être défini ou utilisé correctement
      label: i18n.t('page.home.all'),
      data: data.body,
      color: 'rgba(85, 52, 165, 1)',
      backgroundColor: 'rgba(210, 200, 234, 0.7)',
    },
    {
      key: 'ongoing',
      label: i18n.t('page.home.ongoing'),
      color: 'rgba(250, 178, 67, 1)',
      backgroundColor: 'rgba(250, 178, 67, 0.2)',
      value: {
        STATUS: 'Ongoing',
      }, // Ceci doit être défini ou utilisé correctement
      data: data.body.filter((event) => {
        const startDate = moment(event.startDate);
        const endDate = startDate.clone().add(event.duration, 'hours'); // Cloner pour ne pas modifier startDate
        return startDate.isSameOrBefore(now) && endDate.isAfter(now);
      }),
    },
    {
      key: 'upcoming',
      label: i18n.t('page.home.upcoming'),
      value: {
        STATUS: 'Upcoming',
      }, // Ceci doit être défini ou utilisé correctement
      data: data.body.filter((event) => {
        const startDate = moment(event.startDate);
        return startDate.isAfter(now);
      }),
      color: 'rgba(45, 220, 163, 1)',
      backgroundColor: 'rgba(45, 220, 163, 0.2)',
    },
    {
      key: 'ended',
      label: i18n.t('page.home.ended'),
      value: {
        STATUS: 'Ended',
      }, // Ceci doit être défini ou utilisé correctement
      data: data.body.filter((event) => {
        const startDate = moment(event.startDate);
        const endDate = startDate.clone().add(event.duration, 'hours'); // Cloner pour ne pas modifier startDate
        return endDate.isBefore(now);
      }),
      color: 'rgba(150, 145, 161, 1)',
      backgroundColor: 'rgba(150, 145, 161, 0.2)',
    },
  ];

  const [selected, setSelected] = useState(Menu[0]);

  return (
    <ProtestContainer>
      <ProtestSection>
        <ProtestHeader>
          <TrendingTitleContainer>
            <ProtestsHeading>{i18n.t('page.home.protests')}</ProtestsHeading>
            <TrendingProtestsText>
              {i18n.t('page.home.browse')}
            </TrendingProtestsText>
          </TrendingTitleContainer>
        </ProtestHeader>
        <HomeCategory
          menu={Menu}
          selected={selected}
          onChange={(value) => {
            setSelected(value);
          }}
        />

        <EventList>
          <div
            style={{
              width: 'calc(100vw-80px)',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
              }}
            >
              {selected &&
                selected.data.map((event, index) => (
                  <EventContainer key={event + index}>
                    <EventCard event={event} />
                  </EventContainer>
                ))}
            </div>
          </div>
        </EventList>
      </ProtestSection>
    </ProtestContainer>
  );
}

export default HomeList;

const ProtestContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    marginTop={32}
    {...props}
  />
);

const PageNavigation = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'space-between'}
    marginTop={20}
    width={'calc(100% - 20px)'}
    {...props}
  />
);

const ContainerWithSvg = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={48}
    height={48}
    padding={15}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(16, 24, 40, 0.05)'}
    {...props}
  />
);

const PageCounter = (props) => (
  <View font={'500 18px Work Sans'} color={'rgba(19, 15, 38, 1)'} {...props} />
);

const ProtestSection = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    gap={20}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const ProtestHeader = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={8}
    alignItems={'center'}
    justifyContent={'space-between'}
    {...props}
  />
);

const TrendingTitleContainer = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    maxWidth={'100%'}
    {...props}
  />
);

const ProtestsHeading = (props) => (
  <View
    flex={'0 0 auto'}
    font={'600 24px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const TrendingProtestsText = (props) => (
  <View
    flex={'0 0 auto'}
    marginTop={8}
    font={'400 16px Work Sans'}
    color={'rgba(131, 124, 146, 1)'}
    {...props}
  />
);

const ButtonContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={16}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    height={48}
    {...props}
  />
);

const EventList = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'row'}
    gap={20}
    alignItems={'flex-start'}
    {...props}
  />
);

const EventContainer = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'1 1 0'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'flex-start'}
    maxWidth={291}
    paddingRight={15.5}
    paddingBottom={10}
    paddingLeft={15.5}
    background={'rgba(255, 255, 255, 1)'}
    border={'1px solid rgba(237, 237, 238, 1)'}
    borderRadius={8}
    boxShadow={'0px 1px 2px rgba(19, 15, 38, 0.04)'}
    {...props}
  />
);
