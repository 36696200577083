export default {
  Register: 'Register',
  page: {
    account: {
      protests: 'Protests',
      participateIn: 'You have been participated in ',
      info: 'Account info',
    },
    event: {
      participants: 'Demonstrators and demands',
      trends: 'Participants trend',
      numberOfParticipants: 'The number of participants over times',
      details: 'Protest details',
      people: 'Number of people who participated',
      ending: 'Ending',
      status: {
        ongoing: 'Currently demonstrating peoples',
        upcoming: 'Number of people who will participate',
        ended: 'Number of people who participated',
        unknown: '',
      },
      mapButtonText: 'Where are they protesting from?',
      quoteSocialShare: `🌍✊ Virtual protest on UNTTY: `,

      quoteSocialSharePart2: `
👉 Does the cause resonate with you? Join us.
Nothing to download, everything is online.
#UNTTY #nowWeCount`,
    },
    home: {
      live: 'Live',
      all: 'All',
      ongoing: 'Ongoing',
      upcoming: 'Upcoming',
      ended: 'Ended',
      protests: 'Protests',
      currentParticipants: 'Current protesters',
      participants: 'All protesters',

      browse: 'Browse all the trending protests',
      join: 'Lets see whats happen here',
    },
    login: {
      welcome: 'Welcome back',
      haveAccount: 'Don’t you have an account?',
      signUp: 'Sign up',
      forgotPassword: 'Forget password',
      orContinueWith: 'Or continue with:',
      createAnAccount: 'Create an account',
      googleSignInProblem:
        'Problem with Google sign in. Please try again or use another sign in method.',
      twitterSignInProblem:
        'Problem with Twitter sign in. Please try again or use another sign in method.',
    },
    register: {
      joinUs: 'Join us',
      signIn: 'Sign in',
      alreadyAccount: 'Already have an account?',
    },
  },
  modal: {},
  form: {
    name: {
      label: 'Name',
      placeholder: 'Enter your name',
      required: 'Name is mandatory',
      format: 'Name is invalid',
    },
    firstName: {
      label: 'First Name',
      placeholder: 'Enter your first name',
      required: 'First Name is required',
      format: 'First Name is invalid',
    },
    lastName: {
      label: 'Last Name',
      placeholder: 'Enter your last name',
      required: 'Last Name is required',
      format: 'Last Name is invalid',
    },
    location: {
      label: 'Location',
      placeholder: 'Enter your location',
      required: 'Location is required',
      format: 'Location is invalid',
    },
    slogan: {
      label: 'Please write your slogan/message to the world',
      placeholder: 'Enter your slogan',
      required: 'Slogan is required',
      format: 'Slogan is invalid',
    },

    anonymous: {
      label: 'Anonymous',
      remain: 'Remain anonymous',
      required: 'Anonymous is required',
      format: 'Anonymous is invalid',
    },
    age: {
      label: 'Age',
      placeholder: 'Enter your age',
      required: 'Age is required',
      format: 'Age is invalid',
    },
    gender: {
      label: 'Gender',
      placeholder: 'Enter your gender',
      required: 'Gender is required',
      format: 'Gender is invalid',
      values: {
        man: 'Man',
        woman: 'Woman',
      },
    },
    email: {
      label: 'Email',
      placeholder: 'exemple@mail.com',
      required: 'Email is required',
      format: 'Email is invalid',
    },
    password: {
      label: 'Password',
      placeholder: '******',
      required: 'Password required',
      short: 'Password too short',
      match: "Passwords doesn't match",
    },
  },
  button: {
    join: 'Join Now',
    login: 'Sign in',
    leave: 'Leave',
    register: 'Create an account',
    here: 'I am still here',
    uploadImage: 'Upload image',
    backtoHome: 'Back to home',
    updateAccount: 'Save',
    createProtest: 'Create a new protest',
  },
  format: {
    date: 'MM/DD/YYYY',
    day: 'DD MMMM YYYY',
    time: 'hh:mm',
    datetime: 'MM/DD/YYYY - hh:mm',
  },
  error: {
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    network: 'No internet connection',
  },
  action: {
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    yes: 'yes',
    no: 'no',
    save: 'Save',
    send: 'Send',
    validate: 'Validate',
    logout: 'Logout',
    edit: 'Edit',
    remove: 'Supprimer',
    accept: 'Accept',
    decline: 'Decline',
  },
};
