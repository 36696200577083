export default {
  Register: 'S’inscrire',
  page: {
    account: {
      protests: 'Manifestations',
      participateIn: 'Vous avez participé à ',
      info: 'Infos du compte',
    },
    event: {
      participants: 'Les revendications...',
      trends: 'Tendance des participants',
      numberOfParticipants: 'Nombre de participants au fil du temps',
      details: 'Détails de la manifestation',
      people: 'Nombre de personnes ayant participé',
      ending: 'Fin',
      status: {
        ongoing: 'Personnes en train de manifester',
        upcoming: 'Nombre de personnes qui participeront',
        ended: 'Nombre de personnes ayant participé',
        unknown: '',
      },
      mapButtonText: "D'où manifestent-ils ?",
      quoteSocialShare: `🌍✊ Manifestation virtuelle sur UNTTY: `,

      quoteSocialSharePart2: `
👉 La cause te parle? Rejoins nous.
Rien à télécharger, ça se passe sur le site.
#UNTTY #nowWeCompt`,
    },
    home: {
      live: 'En direct',
      all: 'Tous',
      ongoing: 'En cours',
      upcoming: 'À venir',
      ended: 'Terminé',
      protests: 'Manifestations',
      currentParticipants: 'Manifestent actuellement',
      participants: 'Manifestants comptabilisés',
      browse: 'Parcourir toutes les manifestations tendances',
      join: 'Voir la manifestation',
    },
    login: {
      welcome: 'Bon retour',
      haveAccount: 'Vous n’avez pas de compte ?',
      signUp: 'S’inscrire',
      forgotPassword: 'Mot de passe oublié',
      orContinueWith: 'Ou continuer avec:',
      createAnAccount: 'Créer un compte',
      googleSignInProblem:
        'Problème de connexion avec Google. Veuillez réessayer ou utiliser un autre moyen de connexion.',
      twitterSignInProblem:
        'Problème de connexion avec Twitter. Veuillez réessayer ou utiliser un autre moyen de connexion.',
    },
    register: {
      joinUs: 'Rejoignez-nous',
      signIn: 'Se connecter',
      alreadyAccount: 'Vous avez déjà un compte ?',
    },
  },
  modal: {},
  form: {
    name: {
      label: 'Nom',
      placeholder: 'Entrez votre nom',
      required: 'Le nom est obligatoire',
      format: 'Le nom est invalide',
    },
    firstName: {
      label: 'Prénom',
      placeholder: 'Entrez votre prénom',
      required: 'Le prénom est requis',
      format: 'Le prénom est invalide',
    },
    lastName: {
      label: 'Nom de famille',
      placeholder: 'Entrez votre nom de famille',
      required: 'Le nom de famille est requis',
      format: 'Le nom de famille est invalide',
    },
    location: {
      label: 'Lieu',
      placeholder: 'Entrez votre lieu',
      required: 'Le lieu est requis',
      format: 'Le lieu est invalide',
    },
    slogan: {
      label: 'Veuillez écrire votre slogan/message pour le monde',
      placeholder: 'Entrez votre slogan',
      required: 'Le slogan est requis',
      format: 'Le slogan est invalide',
    },

    anonymous: {
      label: 'Anonyme',
      remain: 'Rester anonyme',
      required: 'L’anonymat est requis',
      format: 'L’anonymat est invalide',
    },
    age: {
      label: 'Âge',
      placeholder: 'Entrez votre âge',
      required: 'L’âge est requis',
      format: 'L’âge est invalide',
    },
    gender: {
      label: 'Genre',
      placeholder: 'Entrez votre genre',
      required: 'Le genre est requis',
      format: 'Le genre est invalide',
      values: {
        man: 'Homme',
        woman: 'Femme',
      },
    },
    email: {
      label: 'Email',
      placeholder: 'exemple@mail.com',
      required: 'L’email est requis',
      format: 'L’email est invalide',
    },
    password: {
      label: 'Mot de passe',
      placeholder: '******',
      required: 'Le mot de passe est requis',
      short: 'Mot de passe trop court',
      match: 'Les mots de passe ne correspondent pas',
    },
  },
  button: {
    join: 'Rejoindre maintenant',
    login: 'Se connecter',
    leave: 'Quitter',
    register: 'Créer un compte',
    here: 'Je suis toujours là',
    uploadImage: 'Télécharger une image',
    backtoHome: 'Retour à l’accueil',
    updateAccount: 'Enregistrer',
    createProtest: 'Créer une nouvelle manifestation',
  },
  format: {
    date: 'JJ/MM/AAAA',
    day: 'JJ MMMM AAAA',
    time: 'hh:mm',
    datetime: 'JJ/MM/AAAA - hh:mm',
  },
  error: {
    error: 'Erreur',
    warning: 'Avertissement',
    info: 'Info',
    network: 'Pas de connexion internet',
  },
  action: {
    ok: 'Ok',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    yes: 'oui',
    no: 'non',
    save: 'Enregistrer',
    send: 'Envoyer',
    validate: 'Valider',
    logout: 'Déconnexion',
    edit: 'Modifier',
    remove: 'Supprimer',
    accept: 'Accepter',
    decline: 'Refuser',
  },
};
