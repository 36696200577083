function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <defs>
          <clipPath id="clip0_100_19747" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="18"
              width="18"
              fill="white"
              transform="translate(3.0000499999999874, 3.25)"
            />
          </clipPath>
        </defs>
        <g id="chevron-down" xmlns="http://www.w3.org/2000/svg">
          <g id="hide 2" clipPath="url(#clip0_100_19747)">
            <path
              id="Hide"
              d="M14.23505,12.152l1.022,-1.023c0.074,0.279 0.118,0.57 0.118,0.871c0,1.861 -1.514,3.375 -3.375,3.375c-0.302,0 -0.592,-0.044 -0.871,-0.118l1.023,-1.022c1.117,-0.077 2.006,-0.966 2.083,-2.083zM20.92905,11.727c-0.069,-0.125 -1.05,-1.844 -2.968,-3.301l-0.819,0.818c1.399,1.029 2.285,2.228 2.634,2.757c-0.667,1.015 -3.282,4.499 -7.776,4.499c-0.674,0 -1.301,-0.086 -1.89,-0.224l-0.924,0.925c0.849,0.26 1.783,0.424 2.814,0.424c5.888,0 8.808,-5.133 8.929,-5.352c0.095,-0.17 0.095,-0.376 0,-0.546zM18.58505,6.21l-12.375,12.375c-0.11,0.11 -0.254,0.165 -0.398,0.165c-0.143,0 -0.287,-0.055 -0.397,-0.165c-0.22,-0.22 -0.22,-0.575 0,-0.795l1.574,-1.575c-2.545,-1.536 -3.838,-3.798 -3.918,-3.942c-0.095,-0.17 -0.095,-0.376 0,-0.546c0.121,-0.219 3.041,-5.352 8.929,-5.352c1.523,0 2.841,0.347 3.969,0.861l1.821,-1.821c0.22,-0.22 0.576,-0.22 0.795,0c0.22,0.22 0.22,0.576 0,0.795zM7.82805,15.377l1.424,-1.425c-0.393,-0.551 -0.627,-1.224 -0.627,-1.952c0,-1.861 1.514,-3.375 3.375,-3.375c0.728,0 1.401,0.234 1.952,0.627l1.152,-1.151c-0.91,-0.365 -1.944,-0.601 -3.104,-0.601c-4.494,0 -7.109,3.484 -7.776,4.499c0.424,0.642 1.64,2.266 3.604,3.378zM10.06905,13.136l3.067,-3.067c-0.335,-0.198 -0.72,-0.319 -1.136,-0.319c-1.241,0 -2.25,1.009 -2.25,2.25c0,0.416 0.121,0.801 0.319,1.136z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
