import React from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import {
  Button,
  Horizontal,
  Left,
  FormikForm,
  FormikTextField,
  FormikSelect,
  FormikSwitch,
} from '@app-studio/web';
import * as Yup from 'yup';
import { ServiceService } from 'src/services/api';
import { useAuthStore } from 'src/stores/AuthStore';
import { Label } from 'src/components/Label';
import { View } from 'app-studio';
import { FormItem } from 'src/components/Form';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(i18n.t('form.email.required')),
  name: Yup.string().required(i18n.t('form.name.required')),
  location: Yup.string().required(i18n.t('form.location.required')),
  age: Yup.number().required(i18n.t('form.age.required')),
  gender: Yup.string().nullable().required(i18n.t('form.gender.required')),
  anonymous: Yup.boolean()
    .nullable()
    .required(i18n.t('form.anonymous.required')),
});

const AccountForm = (FormProps: any) => {
  const { user } = useAuthStore();

  const { loading, run } = ServiceService.useOptionsService2({
    onSuccess: (res) => {
      useAuthStore.getState().refresh();
    },
  });

  return (
    <Formik
      onSubmit={(values) => {
        run(user.email, values);
      }}
      validationSchema={validationSchema}
      initialValues={{
        ...user,
      }}
    >
      {({ errors, handleSubmit, isSubmitting, values, handleChange }) => {
        return (
          <FormikForm>
            <View display="flex" flexWrap="wrap" gap={10}>
              <FormItem>
                <Label>{i18n.t('form.name.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.name.placeholder')}
                  name="name"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              <FormItem>
                <Label>{i18n.t('form.email.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.email.placeholder')}
                  name="email"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
              <FormItem>
                <Label>{i18n.t('form.location.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.location.placeholder')}
                  name="location"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
              <FormItem>
                <Label>{i18n.t('form.age.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.age.placeholder')}
                  name="age"
                  type="number"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
              <FormItem>
                <Label>{i18n.t('form.gender.label')}</Label>
                <FormikSelect
                  placeholder={i18n.t('form.gender.placeholder')}
                  name="gender"
                  options={['Male', 'Female']}
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
            </View>
            <FormItem>
              <Horizontal marginVertical={20}>
                <FormikSwitch
                  placeholder={i18n.t('form.anonymous.placeholder')}
                  name="anonymous"
                  shape={'rounded'}
                  variant={'outline'}
                  label={i18n.t('form.anonymous.label')}
                  labelPosition="left"
                />
              </Horizontal>
            </FormItem>
            <Left marginVertical={20}>
              <Button
                isLoading={loading}
                type="submit"
                width={200}
                onClick={handleSubmit}
              >
                {i18n.t('button.updateAccount')}
              </Button>
            </Left>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default AccountForm;
