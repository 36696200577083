function SvgIcon1({ className }) {
  return (
    <div className={`${className}`}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="x" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector_26"
            d="M5.14405,5.1663c0.23,-0.225 0.53,-0.3513 0.85,-0.3513c0.32,0 0.62,0.1263 0.85,0.3513l5.15,5.1516l5.15,-5.1516c0.11,-0.1146 0.24,-0.206 0.39,-0.2689c0.15,-0.0629 0.31,-0.096 0.46,-0.0974c0.16,-0.0014 0.32,0.029 0.47,0.0893c0.15,0.0604 0.28,0.1495 0.39,0.2621c0.12,0.1127 0.21,0.2467 0.27,0.3942c0.06,0.1474 0.09,0.3054 0.08,0.4648c0,0.1593 -0.03,0.3168 -0.09,0.4632c-0.07,0.1464 -0.16,0.2788 -0.27,0.3895l-5.15,5.1516l5.15,5.1516c0.22,0.2263 0.34,0.5294 0.34,0.8441c-0.01,0.3146 -0.13,0.6156 -0.36,0.8381c-0.22,0.2225 -0.52,0.3487 -0.83,0.3514c-0.32,0.0027 -0.62,-0.1182 -0.85,-0.3368l-5.15,-5.1516l-5.15,5.1516c-0.23,0.2186 -0.53,0.3395 -0.85,0.3368c-0.31,-0.0027 -0.61,-0.1289 -0.83,-0.3514c-0.23,-0.2225 -0.35,-0.5235 -0.36,-0.8381c0,-0.3147 0.12,-0.6178 0.34,-0.8441l5.15,-5.1516l-5.15,-5.1516c-0.22,-0.225 -0.35,-0.5302 -0.35,-0.8484c0,-0.3182 0.13,-0.6234 0.35,-0.8484z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
