import React from 'react';
import HomeList from './components/HomeList';
import HomeLive from './components/HomeLive';
import { ServiceService } from 'src/services/api';
import { useMount } from 'app-studio';
import { Vertical } from '@app-studio/web';

export const HomePage = () => {
  const { data, run } = ServiceService.useGetService3();

  useMount(() => {
    run();
  });

  return (
    <Vertical>
      <HomeLive />
      {data && <HomeList data={data} />}
    </Vertical>
  );
};

export default HomePage;
