function SvgIcon1({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
    >
      <g id="vuesax/twotone/microphone-2" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/twotone/microphone-2_2">
          <g id="microphone-2">
            <path
              id="Vector_19"
              d="M10.00045,12.91665c1.829,0 3.31,-1.4917 3.31,-3.3333v-4.5834c0,-1.8416 -1.481,-3.3333 -3.31,-3.3333c-1.829,0 -3.31,1.4917 -3.31,3.3333v4.5834c0,1.8416 1.481,3.3333 3.31,3.3333z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.54388"
            />
            <path
              id="Vector_20"
              d="M8.85045,5.35825c0.745,-0.275 1.556,-0.275 2.301,0"
              opacity="0.4"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.54388"
            />
            <path
              id="Vector_21"
              d="M9.33845,7.12495c0.439,-0.1167 0.894,-0.1167 1.332,0"
              opacity="0.4"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.54388"
            />
            <g id="Group 595" opacity="0.4">
              <path
                id="Vector_22"
                d="M3.66945,8.04195v1.4167c0,3.5166 2.839,6.375 6.331,6.375c3.492,0 6.33,-2.8584 6.33,-6.375v-1.4167"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.54388"
              />
              <path
                id="Vector_23"
                d="M10.00045,15.83325v2.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.54388"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgIcon1;
