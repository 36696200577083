function SvgIcon2({ className = '' }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
    >
      <defs>
        <clipPath id="clip2_7_1314" xmlns="http://www.w3.org/2000/svg">
          <rect
            height="24"
            width="24"
            fill="white"
            transform="translate(24, 24) rotate(-180, 0, 0)"
          />
        </clipPath>
      </defs>
      <g
        id="arrow-left_2"
        clipPath="url(#clip2_7_1314)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Icon_4"
          d="M1.4,12h21.2M22.6,12l-7,-7M22.6,12l-7,7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default SvgIcon2;
