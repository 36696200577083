import { View, Input } from 'app-studio';
import { Button } from '@app-studio/web';
import { useCallback, useState, forwardRef } from 'react';
import WriteMessage from '../WriteMessage';
import SvgIcon1 from './icons/SvgIcon1';

function AnonymousChat() {
  return (
    <SloganContainer1>
      <WriteMessage />
      <AnonymousContainer>
        <AnonymousContainer2>
          <AnonymousText>Remain anonymous</AnonymousText>
          <SvgIcon1 />
          <AnonymousContainer1>
            <RoundedPurpleBox />
          </AnonymousContainer1>
        </AnonymousContainer2>
        {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
        <JoinButton>Join now</JoinButton>
      </AnonymousContainer>
    </SloganContainer1>
  );
}

export default AnonymousChat;

const SloganContainer1 = (props) => (
  <View
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'stretch'}
    justifyContent={'center'}
    paddingTop={31.5}
    paddingBottom={31}
    {...props}
  />
);

const AnonymousContainer = (props) => (
  <View flex={'0 0 auto'} marginTop={64} {...props} />
);

const AnonymousContainer2 = (props) => (
  <View
    display={'flex'}
    flexDirection={'row'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    {...props}
  />
);

const AnonymousText = (props) => (
  <View
    flex={'0 0 auto'}
    font={'500 16px Work Sans'}
    color={'rgba(19, 15, 38, 1)'}
    {...props}
  />
);

const AnonymousContainer1 = (props) => (
  <View
    boxSizing={'border-box'}
    flex={'0 0 auto'}
    width={48}
    marginLeft={16}
    background={'rgba(237, 237, 238, 1)'}
    borderRadius={12}
    {...props}
  />
);

const RoundedPurpleBox = (props) => (
  <View
    boxSizing={'border-box'}
    width={20}
    height={20}
    background={'rgba(85, 52, 165, 1)'}
    borderRadius={'50%'}
    boxShadow={
      '0px 1px 2px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1)'
    }
    {...props}
  />
);

const JoinButton = (props) => (
  <Button
    boxSizing={'border-box'}
    display={'block'}
    width={121}
    height={48}
    marginTop={16}
    font={'500 18px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    cursor={'pointer'}
    background={'linear-gradient(97.59deg, #7a48f3 128.08%, #391989 5.97%)'}
    border={'none'}
    borderRadius={8}
    {...props}
  />
);
