import React, { useState } from 'react';
import { Formik } from 'formik';
import i18n from 'src/utils/locale';
import { Button, FormikSelect, Select, Vertical } from '@app-studio/web';
import {
  FileSVG,
  FormikPassword,
  FormikTextField,
  FormikForm,
} from '@app-studio/web';
import * as Yup from 'yup';
import { isDev } from 'src/utils/env';

import { ServiceService } from 'src/services/api';
import { Center } from '@app-studio/web';
import { useAuthStore } from 'src/stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import { Label } from 'src/components/Label';
import { FormItem } from 'src/components/Form';
import ReCAPTCHA from 'react-google-recaptcha';

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(i18n.t('form.email.required')),
  password: Yup.string()
    .required(i18n.t('form.password.required'))
    .min(4, i18n.t('form.password.short')),
  name: Yup.string().required(i18n.t('form.name.required')),
  location: Yup.string().required(i18n.t('form.location.required')),
  age: Yup.number().required(i18n.t('form.age.required')),
  gender: Yup.string().nullable().required(i18n.t('form.gender.required')),
});

// clé site reCAPTCHA
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const RegisterForm = (FormProps: any) => {
  const { setToken } = useAuthStore();
  const navigate = useNavigate();

  const [recaptchaValue, setRecaptchaValue] = useState<String | null>(null);

  const { loading, run } = ServiceService.useSignupUserService({
    onSuccess: ({ body, statusCode }) => {
      if (statusCode === 409) {
        alert('Before to submit, prove that you are human !');
        return;
      }
      if (body.token) {
        setToken(body.token, body.email);
        navigate('/');
      }
    },
  });
  return (
    <Formik
      onSubmit={(values) => {
        // Inclure le token reCAPTCHA dans les données envoyées ainsi que le nom de la provenance des données
        const dataToSubmit = {
          ...values,
          googleRecaptchaCode: recaptchaValue,
          from: 'register-form',
        };
        run(dataToSubmit);
      }}
      initialValues={{
        email: '',
        password: '',
        name: '',
        location: '',
        age: 18,
        gender: 'Male',
      }}
      validationSchema={validationSchema}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
      }) => {
        return (
          <FormikForm {...FormProps}>
            <Vertical alignItems="center">
              <FormItem>
                <Label>{i18n.t('form.name.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.name.placeholder')}
                  name="name"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              <FormItem>
                <Label>{i18n.t('form.email.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.email.placeholder')}
                  name="email"
                  type="email"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              <FormItem>
                <Label>{i18n.t('form.password.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.password.placeholder')}
                  name="password"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              <FormItem>
                <Label>{i18n.t('form.location.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.location.placeholder')}
                  name="location"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
              <FormItem>
                <Label>{i18n.t('form.age.label')}</Label>
                <FormikTextField
                  placeholder={i18n.t('form.age.placeholder')}
                  name="age"
                  type="number"
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>
              <FormItem>
                <Label>{i18n.t('form.gender.label')}</Label>
                <FormikSelect
                  placeholder={i18n.t('form.gender.placeholder')}
                  name="gender"
                  options={['Male', 'Female']}
                  shape={'rounded'}
                  variant={'outline'}
                />
              </FormItem>

              {/* Composant reCAPTCHA ici */}
              <FormItem>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_KEY || ''}
                  onChange={setRecaptchaValue}
                />
              </FormItem>

              <Center marginVertical={20}>
                <Button
                  isLoading={loading}
                  type="submit"
                  width="40vw"
                  media={{ mobile: { width: '100%' } }}
                  onClick={handleSubmit}
                  isDisabled={loading}
                >
                  {i18n.t('button.register')}
                </Button>
              </Center>
            </Vertical>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
