/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArrayOfUser } from '../models/ArrayOfUser';
import type { ArrayOfVirtualProtest } from '../models/ArrayOfVirtualProtest';
import type { MODEL02a586 } from '../models/MODEL02a586';
import type { MODEL160e28 } from '../models/MODEL160e28';
import type { MODEL3692db } from '../models/MODEL3692db';
import type { MODEL62bf84 } from '../models/MODEL62bf84';
import type { MODEL65f9c7 } from '../models/MODEL65f9c7';
import type { MODEL765e6d } from '../models/MODEL765e6d';
import type { MODELaf786c } from '../models/MODELaf786c';
import type { MODELe850ed } from '../models/MODELe850ed';
import type { User } from '../models/User';
import type { VirtualProtest } from '../models/VirtualProtest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import {
  useRequest,
  UseRequestOption,
  UseRequestProperties,
} from '@app-studio/react-request';
import { JoinBody } from '../models/JoinBody';

/**
 * @param model3692Db
 * @returns MODEL765e6d 200 response
 * @throws ApiError
 */
export const loginUser = (
  model3692Db: MODEL3692db
): CancelablePromise<MODEL765e6d> => {
  return __request({
    method: 'POST',
    path: `/users/login`,
    body: model3692Db,
  });
};

/**
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService = (): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/users/login`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param modeLaf786C
 * @returns string 200 response
 * @returns User 201 response
 * @throws ApiError
 */
export const signupUser = (
  modeLaf786C: MODELaf786c
): CancelablePromise<string | User> => {
  return __request({
    method: 'POST',
    path: `/users/signup`,
    body: modeLaf786C,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService1 = (): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/users/signup`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param email
 * @returns string 200 response
 * @throws ApiError
 */
export const getService = (email: string): CancelablePromise<string> => {
  return __request({
    method: 'GET',
    path: `/users/${email}`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param email
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService2 = (
  email: string,
  body: MODELaf786c
): CancelablePromise<string> => {
  return __request({
    method: 'PUT',
    path: `/users/${email}/update-profile`,
    body,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param email
 * @returns any 200 response
 * @throws ApiError
 */
export const getService1 = (email: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/users/${email}/joined-protests`,
  });
};

/**
 * @param email
 * @returns any 200 response
 * @throws ApiError
 */
export const getService2 = (email: string): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/users/${email}/virtual-protests`,
  });
};

/**
 * @param status
 * @returns ArrayOfVirtualProtest 200 response
 * @throws ApiError
 */
export const listVirtualProtests = (
  status?: string
): CancelablePromise<ArrayOfVirtualProtest> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests`,
    query: {
      status: status,
    },
  });
};

/**
 * @param model62Bf84
 * @returns any 200 response
 * @returns VirtualProtest 201 response
 * @throws ApiError
 */
export const createVirtualProtest = (
  model62Bf84: MODEL62bf84
): CancelablePromise<any | VirtualProtest> => {
  return __request({
    method: 'POST',
    path: `/virtual-protests`,
    body: model62Bf84,
  });
};

/**
 * @returns string 200 response
 * @throws ApiError
 */
export const getService3 = (): CancelablePromise<string> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests-by-status`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService3 = (): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests-by-status`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @returns any 200 response
 * @throws ApiError
 */
export const getService4 = (): CancelablePromise<any> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/main-protest`,
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @returns any 201 response
 * @throws ApiError
 */
export const getService5 = (
  protestId: string
): CancelablePromise<string | any> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const putService = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'PUT',
    path: `/virtual-protests/${protestId}`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const deleteService = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'DELETE',
    path: `/virtual-protests/${protestId}`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService4 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @param pageSize
 * @param page
 * @returns MODEL65f9c7 200 response
 * @throws ApiError
 */
export const getAllProtestParticipants = (
  protestId: string,
  pageSize?: string,
  page?: string
): CancelablePromise<MODEL65f9c7> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/all-participants`,
    query: {
      pageSize: pageSize,
      page: page,
    },
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService5 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/all-participants`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const confirmProtestPresence = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'PUT',
    path: `/virtual-protests/${protestId}/confirm-presence`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param user_temp_avatar
 * @returns string 200 response
 * @throws ApiError
 */
export const uploadImage = (
  user_temp_avatar: string
): CancelablePromise<string> => {
  return __request({
    method: 'POST',
    path: `/users/upload-temp-avatar`,
    responseHeader: 'Access-Control-Allow-Origin',
    body: {
      user_temp_avatar,
    },
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService6 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/confirm-presence`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const putService1 = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'PUT',
    path: `/virtual-protests/${protestId}/exclude-participant`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService7 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/exclude-participant`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const joinProtest = (
  protestId: string,
  body: JoinBody
): CancelablePromise<string> => {
  return __request({
    method: 'POST',
    path: `/virtual-protests/${protestId}/join`,
    responseHeader: 'Access-Control-Allow-Origin',
    body,
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService8 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/join`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const leaveProtest = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'PUT',
    path: `/virtual-protests/${protestId}/leave`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService9 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/leave`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @param sortBy
 * @returns ArrayOfUser 200 response
 * @throws ApiError
 */
export const getProtestParticipants = (
  protestId: string,
  sortBy?: string
): CancelablePromise<ArrayOfUser> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants`,
    query: {
      sortBy: sortBy,
      maxSizeForTheMap: true,
    },
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService10 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/participants`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @param pageSize
 * @param page
 * @returns MODEL02a586 200 response
 * @throws ApiError
 */
export const getProtestParticipantsByAge = (
  protestId: string,
  pageSize?: string,
  page?: string
): CancelablePromise<MODEL02a586> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants-by-age`,
    query: {
      pageSize: pageSize,
      page: page,
    },
  });
};

/**
 * @param protestId
 * @param pageSize
 * @param page
 * @returns MODELe850ed 200 response
 * @throws ApiError
 */
export const getProtestParticipantsByGender = (
  protestId: string,
  pageSize?: string,
  page?: string
): CancelablePromise<MODELe850ed> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants-by-gender`,
    query: {
      pageSize: pageSize,
      page: page,
    },
  });
};

/**
 * @param protestId
 * @param pageSize
 * @param page
 * @returns MODEL160e28 200 response
 * @throws ApiError
 */
export const getProtestParticipantsByLocation = (
  protestId: string,
  pageSize?: string,
  page?: string
): CancelablePromise<MODEL160e28> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants-by-location`,
    query: {
      pageSize: pageSize,
      page: page,
    },
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const getService6 = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants/count`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService11 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'OPTIONS',
    path: `/virtual-protests/${protestId}/participants/count`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const getService7 = (protestId: string): CancelablePromise<string> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/does-user-participate`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

/**
 * @param protestId
 * @returns string 200 response
 * @throws ApiError
 */
export const optionsService12 = (
  protestId: string
): CancelablePromise<string> => {
  return __request({
    method: 'GET',
    path: `/virtual-protests/${protestId}/participants/count-active`,
    responseHeader: 'Access-Control-Allow-Origin',
  });
};

export const useLoginUserService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (model3692Db: MODEL3692db) => void;
  data: MODEL765e6d;
} & UseRequestProperties => {
  return useRequest(loginUser, { method, ...options });
};

export const useOptionsService = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService, { method, ...options });
};

export const useSignupUserService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (modeLaf786C: MODELaf786c) => void;
  data: string | User;
} & UseRequestProperties => {
  return useRequest(signupUser, { method, ...options });
};

export const useOptionsServiceService1 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: string | User;
} & UseRequestProperties => {
  return useRequest(optionsService1, { method, ...options });
};

export const useGetService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (email: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(getService, { method, ...options });
};

export const useOptionsService2 = ({
  method = 'PUT',
  ...options
}: UseRequestOption = {}): {
  run: (email: string, body: MODELaf786c) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService2, { method, ...options });
};

export const useGetService1 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (email: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(getService1, { method, ...options });
};

export const useGetService2 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (email: string) => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(getService2, { method, ...options });
};

export const useListVirtualProtestsService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (status?: string) => void;
  data: ArrayOfVirtualProtest;
} & UseRequestProperties => {
  return useRequest(listVirtualProtests, { method, ...options });
};

export const useCreateVirtualProtestService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (model62Bf84: MODEL62bf84) => void;
  data: any | VirtualProtest;
} & UseRequestProperties => {
  return useRequest(createVirtualProtest, { method, ...options });
};

export const useGetService3 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(getService3, { method, ...options });
};

export const useOptionsService3 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService3, { method, ...options });
};

export const useGetService4 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: () => void;
  data: any;
} & UseRequestProperties => {
  return useRequest(getService4, { method, ...options });
};

export const useGetService5 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string | any;
} & UseRequestProperties => {
  return useRequest(getService5, { method, ...options });
};

export const usePutService = ({
  method = 'PUT',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(putService, { method, ...options });
};

export const useDeleteService = ({
  method = 'DELETE',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(deleteService, { method, ...options });
};

export const useOptionsService4 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService4, { method, ...options });
};

export const useGetAllProtestParticipantsService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, pageSize?: string, page?: string) => void;
  data: MODEL65f9c7;
} & UseRequestProperties => {
  return useRequest(getAllProtestParticipants, { method, ...options });
};

export const useOptionsService5 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService5, { method, ...options });
};

export const useConfirmProtestPresenceService = ({
  method = 'PUT',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(confirmProtestPresence, { method, ...options });
};

export const useUploadImageService = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (user_temp_avatar: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(uploadImage, { method, ...options });
};

export const useOptionsService6 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService6, { method, ...options });
};

export const usePutServiceService = ({
  method = 'PUT',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(putService1, { method, ...options });
};

export const useOptionsService7 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService7, { method, ...options });
};

export const useJoinProtest = ({
  method = 'POST',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, body: JoinBody) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(joinProtest, { method, ...options });
};

export const useOptionsService8 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService8, { method, ...options });
};

export const useLeaveProtestService = ({
  method = 'PUT',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(leaveProtest, { method, ...options });
};

export const useOptionsService9 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService9, { method, ...options });
};

export const useGetProtestParticipants = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, sortBy?: string) => void;
  data: ArrayOfUser;
} & UseRequestProperties => {
  return useRequest(getProtestParticipants, { method, ...options });
};

export const useOptionsService10 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService10, { method, ...options });
};

export const useGetProtestParticipantsByAgeService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, pageSize?: string, page?: string) => void;
  data: MODEL02a586;
} & UseRequestProperties => {
  return useRequest(getProtestParticipantsByAge, { method, ...options });
};

export const useGetProtestParticipantsByGenderService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, pageSize?: string, page?: string) => void;
  data: MODELe850ed;
} & UseRequestProperties => {
  return useRequest(getProtestParticipantsByGender, { method, ...options });
};

export const useGetProtestParticipantsByLocationService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string, pageSize?: string, page?: string) => void;
  data: MODEL160e28;
} & UseRequestProperties => {
  return useRequest(getProtestParticipantsByLocation, { method, ...options });
};

export const useGetService6 = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(getService6, { method, ...options });
};

export const useOptionsService1 = ({
  method = 'OPTIONS',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService11, { method, ...options });
};

export const useGetServiceService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(getService7, { method, ...options });
};

export const useOptionsServiceService = ({
  method = 'GET',
  ...options
}: UseRequestOption = {}): {
  run: (protestId: string) => void;
  data: string;
} & UseRequestProperties => {
  return useRequest(optionsService12, { method, ...options });
};

export const twitterCallback = async (
  oauthToken: string,
  oauthVerifier: string
): Promise<any> => {
  try {
    const response = await __request({
      method: 'GET',
      path: `/users/oauth2/twitter/twitter-callback?oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`,
    });
    return response;
  } catch (error) {
    console.error('Error in twitterCallback:', error);
    return null;
  }
};
