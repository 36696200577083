interface EnvVars {
  REACT_APP_API_URL: string;
  REACT_APP_WS_URL: string;
}

const { REACT_APP_API_URL, REACT_APP_WS_URL }: EnvVars = process.env as any;

export const APP_NAME = 'UNTTY Platform';

export const API_URL = REACT_APP_API_URL;

export const WS_URL = REACT_APP_WS_URL;

export const DEFAULT_LANGUAGE = 'en';
export const PWA = true;
export const DEV = window.location.host.indexOf('localhost') >= 0;

export const AMPLITUDE_KEY = '';
export const BUGSNAG_KEY = '';
export const SMARTLOOK_KEY = '';
export const ONESIGNAL_APP_ID = '';
export const GOOGLE_ANALYTICS_ID = '';
export const GOOGLE_OPTIMIZE_ID = '';
export const GOOGLE_MAPS_KEY = '';
