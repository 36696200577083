import { View, Image } from 'app-studio';

import ProtestParticipants from '../HomeProtestParticipants';
import { Horizontal, Button } from '@app-studio/web';
import { useNavigate } from 'react-router';
import i18n from 'src/utils/locale';

function LiveStreamPage({ data }) {
  const navigate = useNavigate();

  return (
    <ProtestCard
      onClick={() => {
        if (data?.body?.protestID) navigate('/event/' + data.body.protestID);
      }}
    >
      <Horizontal padding={25}>
        <LiveContainer>
          <GradientRoundedBox>
            <Image src="/assets/protest.svg" />
          </GradientRoundedBox>
        </LiveContainer>
        {data?.body.protestID && (
          <LiveText>{i18n.t('page.home.live')}...</LiveText>
        )}
      </Horizontal>

      {data.body.protestID && (
        <ProtestParticipants
          description={data.body.description}
          title={data.body.title}
          id={data.body.protestID}
          activeParticipantsCount={data.activeParticipantsCount}
          firstFourParticipants={data.firstFourParticipants}
        />
      )}
    </ProtestCard>
  );
}

export default LiveStreamPage;

const ProtestCard = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'stretch'}
    // justifyContent={'center'}
    backgroundImage={'url(/assets/circle.svg)'}
    backgroundRepeat={'no-repeat'}
    backgroundPositionX={'right'}
    backgroundSize={'cover'}
    paddingRight={150}
    paddingLeft={20}
    height="100%"
    width={'80%'}
    maxWidth={600}
    cursor="pointer"
    media={{
      mobile: {
        paddingLeft: 0,
        backgroundSize: '90% 330px',
        backgroundPositionX: 0,
        backgroundPositionY: 0,
        backgroundImage: 'url(/assets/small-circle.svg)',
        paddingRight: 0,
        width: '100%',
      },
    }}
    {...props}
  />
);

const LiveContainer = (props) => (
  <View
    alignItems={'center'}
    padding={6.75}
    background={'rgba(85, 52, 165, 0.8)'}
    borderRadius={100}
    {...props}
  />
);

const GradientRoundedBox = (props) => (
  <View
    boxSizing={'border-box'}
    display={'flex'}
    flex={'0 0 auto'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    width={51}
    height={51}
    background={'linear-gradient(130.26deg, #7a48f3 116.29%, #391989 0.68%)'}
    borderRadius={51}
    {...props}
  />
);

const LiveText = (props) => (
  <View
    flex={'0 0 auto'}
    marginLeft={16}
    font={'600 28px Work Sans'}
    color={'rgba(255, 255, 255, 1)'}
    lineHeight={60}
    zIndex={100}
    {...props}
  />
);
